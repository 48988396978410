@font-face {
  font-family: "ToyotaFontRegular";
  src: url("/rental/fonts/toyotafont/ToyotaType-Regular.eot?v=1.7.22");
  src: url("/rental/fonts/toyotafont/ToyotaType-Regular.eot?#iefix&v=1.7.22") format("embedded-opentype"), url("/rental/fonts/toyotafont/ToyotaType-Regular.woff2?v=1.7.22") format("woff2"), url("/rental/fonts/toyotafont/ToyotaType-Regular.woff?v=1.7.22") format("woff"), url("/rental/fonts/toyotafont/ToyotaType-Regular.ttf?v=1.7.22") format("truetype"), url("/rental/fonts/toyotafont/ToyotaType-Regular.svg?v=1.7.22") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "ToyotaFontSemiBold";
  src: url("/rental/fonts/toyotafont/ToyotaType-Semibold.eot?v=1.7.22");
  src: url("/rental/fonts/toyotafont/ToyotaType-Semibold.eot?#iefix&v=1.7.22") format("embedded-opentype"), url("/rental/fonts/toyotafont/ToyotaType-Semibold.woff2?v=1.7.22") format("woff2"), url("/rental/fonts/toyotafont/ToyotaType-Semibold.woff?v=1.7.22") format("woff"), url("/rental/fonts/toyotafont/ToyotaType-Semibold.ttf?v=1.7.22") format("truetype"), url("/rental/fonts/toyotafont/ToyotaType-Semibold.svg?v=1.7.22") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.skip-links {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.skip-link {
  color: #e10a1d;
  font-family: "ToyotaFontSemiBold", Arial, Helvetica, sans-serif;
  font-size: 15px;
  padding: 8px;
  position: absolute;
  height: 30px;
  left: 50%;
  transform: translate(-50%, -100%);
  transition: transform 0.3s;
}
@media (min-width: 768px) {
  .skip-link {
    left: 0;
  }
}

.skip-link:focus {
  transform: translate(-50%, -35%);
}
@media (min-width: 768px) {
  .skip-link:focus {
    transform: translateY(0%);
  }
}

/* SPINNER */
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
@keyframes colors {
  0% {
    stroke: #eeeeee;
  }
  25% {
    stroke: #cc0000;
  }
  50% {
    stroke: #eeeeee;
  }
  75% {
    stroke: #cc0000;
  }
  100% {
    stroke: #eeeeee;
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}
/* END SPINNER */
html {
  font-size: 62.5%;
}

body {
  font-family: "ToyotaFontRegular", Arial, Helvetica, sans-serif;
  font-size: 1.6rem;
  color: #000;
  background-color: #fff;
}

body.home {
  background-color: #f6f6f6;
}

.container.nav-con {
  height: 72px;
}

.container.mob-form {
  padding: 0;
}

a {
  color: #e10a1d;
}
a:hover {
  outline: 1px solid steelblue !important;
  outline-offset: 2px;
  color: #890713;
}
a:visited {
  outline: 1px solid steelblue !important;
  outline-offset: 2px;
}
a:focus {
  outline: 1px solid steelblue !important;
  outline-offset: 2px;
  color: #890713;
}

#modelEbrochure:focus {
  border: 1px solid steelblue !important;
  outline-offset: 2px;
  outline: none;
}

input:focus {
  outline: 1px solid steelblue !important;
  outline-offset: 2px;
}

input.error:focus {
  outline: 1px solid #e10a1d !important;
  outline-offset: 2px;
}

h1 {
  font-family: "ToyotaFontSemiBold", Arial, Helvetica, sans-serif;
  font-size: 40px;
  font-weight: bold;
  line-height: normal;
  margin: 0;
  padding: 0;
}

h2 {
  font-family: "ToyotaFontSemiBold", Arial, Helvetica, sans-serif;
  font-size: 36px;
  font-size: 3.6rem;
  font-weight: bold;
  line-height: normal;
  margin: 0;
  padding: 0;
}

h3 {
  font-family: "ToyotaFontSemiBold", Arial, Helvetica, sans-serif;
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: bold;
  line-height: normal;
  margin: 0;
  padding: 0;
}

h4 {
  font-family: "ToyotaFontSemiBold", Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-size: 2rem;
  font-weight: bold;
  line-height: normal;
  margin: 0;
  padding: 0;
}

h5 {
  font-family: "ToyotaFontSemiBold", Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: normal;
  margin: 0;
  padding: 0;
}

.btn-primary.disabled {
  background-color: #999999;
  border-color: #888888;
}
.btn-primary.disabled:active {
  background-color: #999999;
  border-color: #888888;
}
.btn-primary.disabled:focus {
  background-color: #999999;
  border-color: #888888;
}
.btn-primary.disabled:hover {
  background-color: #999999;
  border-color: #888888;
}

.btn-primary.disabled.active {
  background-color: #999999;
  border-color: #888888;
}

.btn-primary.disabled.focus {
  background-color: #999999;
  border-color: #888888;
}

.btn-primary[disabled] {
  background-color: #999999;
  border-color: #888888;
}
.btn-primary[disabled]:active {
  background-color: #999999;
  border-color: #888888;
}
.btn-primary[disabled]:focus {
  background-color: #999999;
  border-color: #888888;
}
.btn-primary[disabled]:hover {
  background-color: #999999;
  border-color: #888888;
}

.btn-primary[disabled].active {
  background-color: #999999;
  border-color: #888888;
}

.btn-primary[disabled].focus {
  background-color: #999999;
  border-color: #888888;
}

fieldset[disabled] .btn-primary {
  background-color: #999999;
  border-color: #888888;
}
fieldset[disabled] .btn-primary:active {
  background-color: #999999;
  border-color: #888888;
}
fieldset[disabled] .btn-primary:focus {
  background-color: #999999;
  border-color: #888888;
}
fieldset[disabled] .btn-primary:hover {
  background-color: #999999;
  border-color: #888888;
}
fieldset[disabled] .btn-primary.active {
  background-color: #999999;
  border-color: #888888;
}
fieldset[disabled] .btn-primary.focus {
  background-color: #999999;
  border-color: #888888;
}

select {
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
select:-ms-expand {
  display: none;
}

h2.car-tips-h2 {
  font-size: 40px;
  font-weight: bold;
  line-height: normal;
  margin: 0;
  padding: 0;
  color: #fff;
}

h1.car-tips-h1 {
  font-size: 36px;
  font-size: 3.6rem;
  font-weight: bold;
  line-height: normal;
  margin: 0;
  padding: 0;
}

ul {
  padding: 14px;
}

li {
  line-height: 24px;
}

.red {
  color: #e10a1d;
}

.btn {
  border-radius: 0;
  padding: 10px 20px;
}
.btn i {
  font-size: 1.3em;
  margin-right: 6px;
}
.btn:focus {
  outline: 1px solid steelblue !important;
  outline-offset: 2px;
  background-color: #a9141a !important;
}
.btn:active {
  outline: 1px solid steelblue !important;
  outline-offset: 2px;
  background-color: #a9141a !important;
}

.btn-primary.active {
  color: #fff;
  background-color: #e10a1d !important;
  border: none;
}

.btn-primary {
  padding: 19px 20px 18px;
  color: #fff;
  background-color: #e10a1d;
  border: none;
}
.btn-primary:active {
  color: #fff;
  background-color: #e10a1d !important;
  border: none;
}
.btn-primary:hover {
  background-color: #a9141a !important;
  color: #fff !important;
}

.open > .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #e10a1d !important;
  border: none;
}

.form-control {
  padding: 6px 8px;
  border-radius: 0;
  border: 1px solid #717171;
}
.form-control:focus {
  outline: 1px solid steelblue !important;
  outline-offset: 2px;
}

.btn-default {
  color: #fff;
  background-color: #666;
  border: none;
}

.popover {
  width: 150px;
}
.popover .popover-content {
  font-size: 11px;
  overflow-wrap: break-word;
}

.navbar {
  border-radius: 0;
  margin: 0;
  min-height: inherit;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
}
.navbar ul {
  padding: 0;
}

.navbar-inverse {
  background-color: rgb(255, 255, 255);
  border: 0;
  border-bottom: 1px solid #d8d8d8;
  min-height: 73px;
}
.navbar-inverse .navbar-collapse {
  border: none;
  padding: 18px 0 10px 0;
}
.navbar-inverse .navbar-collapse .panel {
  display: none;
  border: none;
  background: none;
  border-radius: 0px;
  margin-bottom: 0;
}
.navbar-inverse .navbar-collapse .panel a {
  border-left: none;
  border-right: none;
}
.navbar-inverse .navbar-collapse .panel #rental-vehicles a {
  padding-left: 40px;
}
.navbar-inverse .navbar-collapse .panel #rental-request a {
  padding-left: 40px;
}
.navbar-inverse .navbar-collapse .panel .list-group-submenu a {
  font-family: "ToyotaFontSemiBold", Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: bold;
}
.navbar-inverse .navbar-form {
  border: none;
}
.navbar-inverse .navbar-form .panel {
  display: none;
  border: none;
  background: none;
  border-radius: 0px;
  margin-bottom: 0;
}
.navbar-inverse .navbar-form .panel a {
  border-left: none;
  border-right: none;
}
.navbar-inverse .navbar-form .panel #rental-vehicles a {
  padding-left: 40px;
}
.navbar-inverse .navbar-form .panel #rental-request a {
  padding-left: 40px;
}
.navbar-inverse .navbar-form .panel .list-group-submenu a {
  font-family: "ToyotaFontSemiBold", Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: bold;
}
.navbar-inverse .stripe {
  display: none;
}
.navbar-inverse .navbar-inverse .navbar-nav > li > a:visited {
  color: #333;
}
.navbar-inverse .navbar-nav {
  margin: 0 -15px 0 0;
  border: 1px solid #d8d8d8;
}
.navbar-inverse .navbar-nav li.rt-btn {
  display: none;
}
.navbar-inverse .navbar-nav li.rt-btn a:hover {
  color: #fff !important;
  background-color: #a9141a;
}
.navbar-inverse .navbar-nav li.rt-btn a:focus {
  color: #fff !important;
  background-color: #a9141a;
  outline: 1px solid steelblue !important;
  outline-offset: 2px;
}
.navbar-inverse .navbar-nav .line-item {
  background-color: #fff;
}
.navbar-inverse .navbar-nav li {
  line-height: 1;
}
.navbar-inverse .navbar-nav li a {
  padding: 19px 50px 18px 20px;
  color: #000;
  font-size: 16px;
}
.navbar-inverse .navbar-nav li a:visited {
  color: #000;
}
.navbar-inverse .navbar-nav li .panel {
  box-shadow: none !important;
}
.navbar-inverse .navbar-nav li .vehicle-menu-buttons .btn-divider {
  color: #d8d8d8;
  display: inline-block;
  margin: 0 15px;
}
.navbar-inverse .navbar-nav li .vehicle-menu-buttons a {
  font-size: 12px;
  padding: 0;
  color: #e10a1d;
}
.navbar-inverse .navbar-nav .list-item-title {
  background-color: #fff;
}
.navbar-inverse .navbar-nav .list-group-item em {
  font-size: 20px;
  line-height: 22px;
  font-weight: bold;
  color: #9c9c9c;
}
.navbar-inverse .navbar-nav .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.navbar-inverse .navbar-nav .list-group-item:last-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.navbar-inverse .navbar-nav .list-group-item[aria-expanded=true] em {
  color: #e10a1d;
  transform: rotate(180deg);
}
.navbar-inverse .navbar-nav > .open > a {
  background-color: #fff !important;
  color: #e10a1d !important;
}
.navbar-inverse .navbar-nav > .open > a:focus {
  background-color: #fff !important;
  color: #e10a1d !important;
}
.navbar-inverse .navbar-nav > .open > a:hover {
  background-color: #fff !important;
  color: #e10a1d !important;
}
.navbar-inverse .navbar-nav .open .dropdown-menu {
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0;
}
.navbar-inverse .navbar-nav .open .dropdown-menu li a {
  color: #000;
  padding: 19px 15px 18px;
}
.navbar-inverse .navbar-nav .open .dropdown-menu li a:hover {
  background-color: #fff;
  color: #333;
}
.navbar-inverse #menuTabs {
  border: 0;
  border-radius: 0;
}
.navbar-inverse #menuTabs .nav-tabs {
  background-color: #fff;
  border-bottom: 0;
}
.navbar-inverse #menuTabs .tab-content {
  padding: 20px 40px 40px;
}
.navbar-inverse #menuTabs .tab-content .tab-pane {
  max-width: 1170px;
  margin: 0 auto;
  text-align: left;
}
.navbar-inverse .nav > li .rental-veh-btn {
  padding: 19px 20px 18px;
}
.navbar-inverse .nav > li.dropdown.open {
  position: static;
}
.navbar-inverse .nav > li.dropdown.open .desktop-view {
  display: table;
  width: 100%;
  text-align: center;
  left: 0;
  right: 0;
}
.navbar-inverse .nav-tabs {
  text-align: left;
}
.navbar-inverse .nav-tabs li {
  float: none;
  display: inline-block;
  width: 170px;
  text-align: center;
}
.navbar-inverse .nav-tabs li a {
  background-color: #fff;
  border: 0;
  border-radius: 0;
  font-size: 14px;
  margin: 0;
  padding: 15px 20px 0 !important;
}
.navbar-inverse .nav-tabs li a:hover {
  background-color: #fff;
  border: 0;
  border-radius: 0;
  font-size: 14px;
  margin: 0;
  padding: 15px 20px 0 !important;
}
.navbar-inverse .nav-tabs li a:visited {
  background-color: #fff;
  border: 0;
  border-radius: 0;
  font-size: 14px;
  margin: 0;
  padding: 15px 20px 0 !important;
}
.navbar-inverse .nav-tabs li a:focus {
  background-color: #fff;
  border: 0;
  border-radius: 0;
  font-size: 14px;
  margin: 0;
  padding: 15px 20px 0 !important;
}
.navbar-inverse .nav-tabs li::after {
  content: "";
  display: block;
  margin: 8px auto auto auto;
  height: 2px;
  width: 0px;
  background: transparent;
  transition: width 0.2s ease, background-color 0.2s ease;
}
.navbar-inverse .nav-tabs li:hover::after {
  width: 100%;
  background: #c00;
}
.navbar-inverse .nav-tabs .cat-divider {
  color: #d8d8d8;
  display: inline-block;
  vertical-align: middle;
}
.navbar-inverse .nav-tabs .active:after {
  background: #e10a1d;
  content: "";
  display: block;
  height: 2px;
  width: 100%;
}
.navbar-inverse .navbar-brand {
  position: relative;
  width: 234px;
  height: 48px;
  padding: 0;
  top: 10px;
  left: 10px;
}
.navbar-inverse .navbar-toggle {
  border: 0;
  color: #fff;
  margin-top: 11px;
  margin-right: 20px;
  padding-right: 0;
}
.navbar-inverse .navbar-toggle .icon-bar {
  transition: 300ms ease-in-out;
  background-color: #333;
  position: relative;
  width: 24px;
  height: 3px;
}
.navbar-inverse .navbar-toggle .icon-bar:last-child {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: -7px;
}
.navbar-inverse .navbar-toggle .icon-bar:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 7px;
}
.navbar-inverse .navbar-toggle .icon-bar:nth-child(3) {
  opacity: 0;
}
.navbar-inverse .navbar-toggle:focus {
  background: none !important;
}
.navbar-inverse .navbar-toggle:hover {
  background: none !important;
}
.navbar-inverse .navbar-toggle.collapsed .icon-bar {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  top: 0;
  opacity: 1;
}

.mobile-loc {
  position: absolute;
  top: 7px;
  right: 60px;
}
.mobile-loc p {
  text-align: center;
  font-family: "ToyotaFontSemiBold", Arial, Helvetica, sans-serif;
  font-size: 11px;
  position: relative;
  top: -2px;
  color: #000;
}
.mobile-loc img {
  position: relative;
  left: 6px;
  width: 24px;
  height: 24px;
}

.dropdown-toggle > .fa-angle-down {
  position: relative;
  color: #e10a1d;
  font-size: 18px;
  margin-left: 2px;
  top: 2px;
}

.dropup > .fa-angle-down {
  transform: rotate(180deg);
}

.home-search-box {
  width: 100%;
  padding: 25px 15px 25px 15px;
  background-color: #fff;
  margin: 0 auto;
}
.home-search-box h2 {
  font-size: 21px;
}
.home-search-box .search-option {
  font-size: 12px;
  color: #000;
  display: none;
}
.home-search-box .search-option:hover {
  color: #cc0000;
}
.home-search-box .search-option:focus {
  color: #cc0000;
}
.home-search-box .home-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 15px 0;
}
.home-search-box .home-form .home-form-select {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 60px;
  border: 1px solid #d8d8d8;
  padding-left: 15px;
}
.home-search-box .home-form .home-form-select select {
  background-color: #fff;
  border: none;
  width: 98%;
  cursor: pointer;
}
.home-search-box .home-form .home-form-select select:focus {
  outline: 1px solid steelblue !important;
  outline-offset: 2px;
}
.home-search-box .home-form .home-form-select .select-arrow {
  margin-left: -20px;
}
.home-search-box .home-form .search-by-zip {
  width: 100%;
  display: none;
}
.home-search-box .home-form .search-by-zip .search-zip-in {
  display: flex;
  min-height: 60px;
  height: 60px;
  width: 100%;
  border: 1px solid #d8d8d8;
  padding-left: 15px;
  margin-top: 15px;
}
.home-search-box .home-form .search-by-zip .search-zip-in input {
  border: none;
  height: 25px;
  display: flex;
  align-self: center;
  color: #000;
}
.home-search-box .home-form .search-by-city {
  width: 100%;
  display: none;
}
.home-search-box .home-form .search-by-city .search-state-select {
  display: flex;
  align-items: center;
  min-height: 60px;
  height: 60px;
  width: 100%;
  border: 1px solid #d8d8d8;
  padding-left: 15px;
  margin-top: 15px;
}
.home-search-box .home-form .search-by-city .search-state-select .select-arrow {
  margin-left: -20px;
}
.home-search-box .home-form .search-by-city .search-state-select select {
  background-color: #fff;
  border: none;
  width: 98%;
}
.home-search-box .home-form .search-by-city .search-state-select select:focus {
  outline: 1px solid steelblue !important;
  outline-offset: 2px;
}
.home-search-box .home-form .search-by-city .search-city-in {
  display: flex;
  align-items: center;
  min-height: 60px;
  height: 60px;
  width: 100%;
  border: 1px solid #d8d8d8;
  padding-left: 15px;
  margin-top: 15px;
}
.home-search-box .home-form .search-by-city .search-city-in input {
  border: none;
  width: 90%;
  color: #000;
}
.home-search-box .home-form .search-btn {
  display: flex;
  margin-top: 15px;
}
.home-search-box .home-form .search-btn a {
  font-family: "ToyotaFontSemiBold", Arial, Helvetica, sans-serif;
  padding: 19px 20px 18px;
  width: 100%;
}
.home-search-box .home-form .home-search-error {
  display: none;
}
.home-search-box .home-form .home-search-error.active {
  display: block;
  position: absolute;
  bottom: -19px;
  color: #e10a1d;
  font-size: 12px;
}
.home-search-box .home-form .search-by-zip.active {
  display: flex;
  flex-direction: row;
}
.home-search-box .home-form .search-by-city.active {
  display: flex;
  flex-direction: row;
}
.home-search-box .home-form .search-by-zip.active.error {
  border: 1px solid #e10a1d;
}
.home-search-box .home-form .search-by-city.active.tcuv-form-field-control {
  border: 1px solid #e10a1d;
}
.home-search-box .search-option.active {
  display: inline-block;
}

.rt-con {
  max-width: 980px;
  padding: 0;
}

.home-hero-content {
  margin: -25px auto 10px;
  position: relative;
  width: 90%;
  z-index: 2;
}

.segments {
  margin-top: 40px;
  padding: 0 30px;
}
.segments a {
  border: none;
  outline: none;
  text-decoration: none;
}
.segments a:visited {
  border: none;
  outline: none;
  text-decoration: none;
}
.segments a:hover {
  border: none;
  outline: none;
  text-decoration: none;
}
.segments a:hover .link {
  color: #a9141a;
  text-decoration: underline;
}
.segments a:focus {
  border: none;
  outline: none;
  text-decoration: none;
}
.segments a:focus .link {
  color: #a9141a;
  text-decoration: underline;
}
.segments h2 {
  font-size: 21px;
  font-weight: normal;
}
.segments .segment {
  margin: 0 10px;
}
.segments .segment .title {
  font-family: "ToyotaFontSemiBold", Arial, Helvetica, sans-serif;
  color: #000;
  font-size: 21px;
  white-space: nowrap;
}
.segments .segment .count {
  color: #717171;
  font-size: 9px;
}
.segments .segment .photo {
  display: table-cell;
  height: 112px;
  vertical-align: bottom;
}
.segments .segment .photo img {
  width: 100%;
  max-height: 91px;
}
.segments .segment .photo.cars img {
  margin: 10px 0;
  width: 96%;
}
.segments .segment .photo.trucks img {
  margin: 13px 0 0 0;
  width: 100%;
}
.segments .segment .photo.suvs img {
  margin: 9px 0 11px;
  width: 94%;
}
.segments .segment .photo.hybrids img {
  margin: 8px 0 0 0;
  width: 96%;
}
.segments .segment .link {
  font-family: "ToyotaFontSemiBold", Arial, Helvetica, sans-serif;
  font-size: 12px;
  border-top: 1px solid #d8d8d8;
  padding-top: 10px;
  margin-top: 10px;
}
.segments .segment .link:hover {
  color: #e10a1d;
  text-decoration: underline;
}

.slider-panel-content {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 20px 0px;
}
@media (min-width: 768px) {
  .slider-panel-content {
    flex-direction: row;
  }
}

.fad-btn {
  margin-bottom: 50px;
}
.fad-btn a {
  font-family: "ToyotaFontSemiBold", Arial, Helvetica, sans-serif;
  padding: 19px 20px 18px;
}

.home-intro {
  padding: 30px 0;
  margin-top: 50px;
}
.home-intro .container {
  padding-left: 30px;
  padding-right: 30px;
}
.home-intro .home-intro-content {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.home-intro .home-intro-content .left,
.home-intro .home-intro-content .right {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.home-intro .home-intro-content .left a,
.home-intro .home-intro-content .right a {
  display: inline;
}
.home-intro .copy {
  display: inline;
  order: 0;
  font-size: 12px;
  line-height: 1.8;
}
.home-intro h1 {
  font-size: 32px;
  margin-bottom: 10px;
  line-height: 1.1;
}
.home-intro h1 span {
  color: #e10a1d;
}
.home-intro .video {
  display: flex;
  margin: 30px 0 0 0;
  position: relative;
}
.home-intro .video img {
  width: 100%;
}

.poster {
  display: none;
}

.poster-hov {
  display: none;
}

.poster.active {
  display: block;
}

.poster-hov.active {
  display: block;
}

.slick-slide a {
  color: steelblue;
}

.models-content {
  margin: 30px 0;
}
.models-content p {
  margin: 20px 0;
}
.models-content .popover {
  width: 240px;
}

.models-subnav {
  background: #eee;
  margin-top: 10px;
}

.nav-tabs-models {
  background-color: #eee;
  border-bottom: 0;
  font-size: 0;
  padding: 4px 0 0 0;
  text-align: center;
}
.nav-tabs-models li {
  background: #eee;
  display: inline-block;
  float: none;
  vertical-align: middle;
  width: 20%;
}
.nav-tabs-models li a {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  color: #333;
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 19px;
  margin: 0;
  padding: 20px 20px 22px !important;
}
.nav-tabs-models li a:hover {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  color: #333;
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 19px;
  margin: 0;
  padding: 20px 20px 22px !important;
}
.nav-tabs-models li a:visited {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  color: #333;
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 19px;
  margin: 0;
  padding: 20px 20px 22px !important;
}
.nav-tabs-models li a:focus {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  color: #333;
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 19px;
  margin: 0;
  padding: 20px 20px 22px !important;
}
.nav-tabs-models .active {
  background: #fff;
}

.models-list {
  display: flex;
  flex-direction: column;
  margin: 40px auto;
  text-align: center;
  padding: 0 30px;
}

.menu-vehicle {
  display: inline-block;
  margin-bottom: 10px;
}
.menu-vehicle:hover {
  cursor: pointer;
}
.menu-vehicle .vehicle-flags {
  position: relative;
  min-height: 15px;
  text-align: left;
  line-height: 1.2;
}
.menu-vehicle .vehicle-flags span {
  font-family: "ToyotaFontRegular", Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: #717171;
}
.menu-vehicle .vehicle-menu-photo {
  height: 76px;
}
.menu-vehicle .vehicle-menu-photo:focus {
  outline: 1px solid steelblue !important;
  outline-offset: 2px;
}
.menu-vehicle .yaris {
  background: url(/rental/img/jellies/yaris.png) 9px 0px/180px 76px no-repeat;
}
.menu-vehicle .corolla {
  background: url(/rental/img/jellies/corolla.png) 9px 0px/180px 76px no-repeat;
}
.menu-vehicle .camry {
  background: url(/rental/img/jellies/camry.png) 9px 0px/180px 76px no-repeat;
}
.menu-vehicle .avalon {
  background: url(/rental/img/jellies/avalon.png) 9px 0px/180px 76px no-repeat;
}
.menu-vehicle .sienna {
  background: url(/rental/img/jellies/sienna.png) 9px 0px/180px 76px no-repeat;
}
.menu-vehicle .eighty-six {
  background: url(/rental/img/jellies/86.png) 9px 0px/180px 76px no-repeat;
}
.menu-vehicle .yaris-ia {
  background: url(/rental/img/jellies/yaris-ia.png) 9px 0px/180px 76px no-repeat;
}
.menu-vehicle .corolla-im {
  background: url(/rental/img/jellies/corolla-im.png) 9px 0px/180px 76px no-repeat;
}
.menu-vehicle .corollahatchback {
  background: url(/rental/img/jellies/corollahatchback.png) 9px 0px/180px 76px no-repeat;
}
.menu-vehicle .tacoma {
  background: url(/rental/img/jellies/tacoma.png) 9px 0px/180px 76px no-repeat;
}
.menu-vehicle .tundra {
  background: url(/rental/img/jellies/tundra.png) 9px 0px/180px 76px no-repeat;
}
.menu-vehicle .c-hr {
  background: url(/rental/img/jellies/chr.png) 9px 0px/180px 76px no-repeat;
}
.menu-vehicle .rav4 {
  background: url(/rental/img/jellies/rav4.png) 9px 0px/180px 76px no-repeat;
}
.menu-vehicle .venza {
  background: url(/rental/img/jellies/venza.png) 9px 0px/180px 76px no-repeat;
}
.menu-vehicle .highlander {
  background: url(/rental/img/jellies/highlander.png) 9px 0px/180px 76px no-repeat;
}
.menu-vehicle .fourrunner {
  background: url(/rental/img/jellies/4runner.png) 9px 0px/180px 76px no-repeat;
}
.menu-vehicle .sequoia {
  background: url(/rental/img/jellies/sequoia.png) 9px 0px/180px 76px no-repeat;
}
.menu-vehicle .landcruiser {
  background: url(/rental/img/jellies/landcruiser.png) 9px 0px/180px 76px no-repeat;
}
.menu-vehicle .camry-hybrid {
  background: url(/rental/img/jellies/camry-hybrid.png) 9px 0px/180px 76px no-repeat;
}
.menu-vehicle .avalon-hybrid {
  background: url(/rental/img/jellies/avalon-hybrid.png) 9px 0px/180px 76px no-repeat;
}
.menu-vehicle .rav4-hybrid {
  background: url(/rental/img/jellies/rav4-hybrid.png) 9px 0px/180px 76px no-repeat;
}
.menu-vehicle .highlander-hybrid {
  background: url(/rental/img/jellies/highlander-hybrid.png) 9px 0px/180px 76px no-repeat;
}
.menu-vehicle .prius {
  background: url(/rental/img/jellies/prius.png) 9px 0px/180px 76px no-repeat;
}
.menu-vehicle .priusprime {
  background: url(/rental/img/jellies/priusprime.png) 9px 0px/180px 76px no-repeat;
}
.menu-vehicle .priusc {
  background: url(/rental/img/jellies/priusc.png) 9px 0px/180px 76px no-repeat;
}
.menu-vehicle .priusv {
  background: url(/rental/img/jellies/priusv.png) 9px 0px/180px 76px no-repeat;
}
.menu-vehicle .corolla-hybrid {
  background: url(/rental/img/jellies/corolla-hybrid.png) 9px 0px/180px 76px no-repeat;
}
.menu-vehicle .vehicle-menu-info {
  margin: 0;
  text-align: left;
}
.menu-vehicle .vehicle-menu-info .vehicle-menu-title {
  font-family: "ToyotaFontSemiBold", Arial, Helvetica, sans-serif;
  font-size: 17px;
  font-weight: bold;
  margin: 0;
}
.menu-vehicle .vehicle-menu-info .vehicle-menu-mpg {
  font-family: "ToyotaFontRegular", Arial, Helvetica, sans-serif;
  float: left;
  font-size: 9px;
  color: #717171;
  margin: 10px 0 14px 0;
}
.menu-vehicle .vehicle-menu-info .vehicle-menu-mpg-disclaimer {
  display: inline;
  position: relative;
  top: -3px;
  min-width: 12px;
}
.menu-vehicle .vehicle-menu-info .vehicle-menu-mpg-disclaimer sup {
  position: relative;
  top: 4px;
}
.menu-vehicle .vehicle-menu-info .vehicle-menu-mpg-disclaimer sup a {
  font-family: "ToyotaFontSemiBold", Arial, Helvetica, sans-serif;
  font-weight: 600;
}
.menu-vehicle .vehicle-menu-info .vehicle-menu-mpg-disclaimer a {
  color: #e10a1d;
  font-size: 12px;
  padding: 0;
}
.menu-vehicle .vehicle-menu-info .vehicle-menu-mpg-disclaimer a:hover {
  color: #a9141a;
}
.menu-vehicle .vehicle-menu-info .vehicle-menu-mpg-disclaimer a:visited {
  color: #e10a1d;
}
.menu-vehicle .vehicle-menu-buttons {
  width: 100%;
  border-top: 1px solid #d8d8d8;
  text-align: left;
  padding: 10px 0 15px 0;
}
.menu-vehicle .vehicle-menu-buttons a:hover {
  text-decoration: underline;
  color: #890713;
}

.vehicle-menu-buttons .btn-divider {
  color: #d8d8d8;
  display: inline-block;
  margin: 0 15px;
}
.vehicle-menu-buttons a {
  font-size: 12px;
  padding: 0;
  color: #e10a1d;
}
.vehicle-menu-buttons a:hover {
  text-decoration: underline;
}

footer {
  background-color: #f6f6f6;
  font-size: 12px;
  border-top: 1px solid #d8d8d8;
}
footer .ccpa-links {
  text-align: left;
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 15px;
  padding-left: 15px;
  background-color: #fff;
}
footer .ccpa-links .no-sell {
  font-family: "ToyotaFontSemiBold", Arial, Helvetica, sans-serif;
  display: inline-block;
  text-transform: uppercase;
  color: #e10a1d;
  font-size: 1.4rem;
}
footer .ccpa-links .no-sell:hover {
  color: #890713;
  outline: 1px solid steelblue !important;
  outline-offset: 2px;
}
footer .ccpa-links .no-sell:focus {
  color: #890713;
  text-decoration: underline !important;
  outline: 1px solid steelblue !important;
  outline-offset: 2px;
}
footer .ccpa-links .privacy-icon {
  position: relative;
  top: -2px;
  margin-left: 5px;
}
footer .ccpa-links .consent {
  font-family: "ToyotaFontSemiBold", Arial, Helvetica, sans-serif;
  display: inline-block;
  padding-top: 5px;
  color: #000;
}
footer .ccpa-links .consent:hover {
  color: #890713;
  outline: 1px solid steelblue !important;
  outline-offset: 2px;
}
footer .ccpa-links .consent:focus {
  color: #890713;
  outline: 1px solid steelblue !important;
  outline-offset: 2px;
}
footer a {
  color: #000;
  text-decoration: none !important;
}
footer a:visited {
  color: #000;
  text-decoration: none !important;
}
footer a:hover {
  color: #e10a1d;
}
footer a:focus {
  color: #e10a1d;
  text-decoration: underline !important;
}
footer .footer-col {
  margin-top: 30px;
}
footer .footer-col strong {
  font-family: "ToyotaFontSemiBold", Arial, Helvetica, sans-serif;
  font-size: 15px;
  color: #000;
}
footer ul {
  list-style-type: none;
  margin: 6px 0 0 0;
  padding: 0;
}
footer .icon-container {
  display: inline;
  height: 45px;
  margin: 0 6px;
  line-height: 45px;
}
footer .icon-container img {
  width: 45px;
  display: inline-block;
  transition: 0.3s;
}
footer .icon-container:hover {
  opacity: 0.75;
}
footer .icon-container:hover img {
  border-radius: 100%;
  box-shadow: 0 0 0 1px #000;
}
footer .panel-group {
  margin-bottom: 0;
}
footer .panel-group .panel {
  background-color: transparent;
  border-radius: 0;
  border-bottom: 1px solid #ccc;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  margin-bottom: 0;
  box-shadow: none;
}
footer .panel-group .panel .panel-body {
  padding: 0px;
}
footer .panel-group .panel .panel-body ul {
  background-color: #fff;
  margin: 0;
}
footer .panel-group .panel .panel-body li {
  font-size: 16px;
  padding: 15px 25px 15px 0;
  margin-left: 25px;
  margin-right: 25px;
  border-bottom: 1px solid #dadada;
}
footer .panel-group .panel .panel-body li:last-child {
  border-bottom: none;
}
footer .panel-group .panel .panel-body li a {
  display: inline-block;
  background-color: #fff;
  font-size: 12px;
}
footer .panel-group .panel + .panel {
  margin-top: 0px;
}
footer .panel-group .row {
  margin: 0;
}
footer .panel-group .copyright-row {
  border: 0;
  color: #999;
  margin: 0;
  padding: 20px;
}
footer .panel-group .footer-buttons {
  border-top: 1px solid #cacaca;
  padding: 0;
}
footer .panel-group .footer-buttons a {
  color: #999;
}
footer .panel-group .footer-buttons a:nth-child(2) div {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
footer .panel-group .footer-buttons a .footer-button {
  font-size: 14px;
  font-size: 1.4rem;
  text-align: center;
  padding: 20px;
  width: 33%;
}
footer .panel-group .footer-buttons a .footer-button:hover {
  background-color: #d7d7d7;
}
footer .panel-heading {
  background-color: transparent;
  padding: 16px 20px 14px;
}
footer .panel-heading h4 {
  font-size: 15px;
}
footer .panel-heading h4 i {
  font-size: 30px;
  line-height: 18px;
}
footer .active-panel {
  background: #ccc;
}

.ccpa-con {
  background-color: #f6f6f6;
  padding: 10px 0 20px 0;
  border-bottom: 1px solid #ddd;
}
.ccpa-con .ccpa-links {
  margin-top: 10px;
  background-color: transparent;
}

.footer-social {
  margin: 25px 0 25px 0;
  padding-top: 25px;
  text-align: center;
  border-top: 1px solid #d7d7d7;
  padding-right: 0;
}
.footer-social a {
  position: relative;
  display: inline-block;
  min-width: 50px;
  min-height: 50px;
}
.footer-social a:focus {
  opacity: 0.75;
}

.mobile-social {
  background-color: #f6f6f6;
  padding: 15px 0;
  text-align: center;
  margin-bottom: 10px;
  z-index: 15;
}
.mobile-social .icon-container {
  float: none;
  display: inline;
}

.copyright-row-lg {
  background-color: #fff;
  color: #000;
  padding: 25px 0;
}
.copyright-row-lg .copyright {
  font-size: 10px;
  color: #000;
}
.copyright-row-lg a {
  font-size: 12px;
  display: inline;
  color: #000;
}
.copyright-row-lg a:hover {
  text-decoration: underline;
  color: #e10a1d;
}
.copyright-row-lg a:focus {
  text-decoration: underline;
  color: #e10a1d;
}
.copyright-row-lg .footer-divider {
  margin: 0 8px;
  border-left: 1px solid #b6b6b6;
  display: inline-block;
  vertical-align: middle;
  height: 0.8em;
}

.panel-title .panel-toggle-open {
  display: none;
  float: right;
  width: 15px;
}
.panel-title .panel-toggle-open.active {
  display: block;
}
.panel-title .panel-toggle-close.active {
  display: block;
}

.panel-toggle-close {
  display: none;
  float: right;
  width: 15px;
}

.accordion-toggle:focus {
  text-decoration: none;
}

.error-msg {
  position: absolute;
  top: 105px;
  color: #fff;
  left: -234px;
  background: #ffb400;
  border: 2px solid #fff;
  font-size: 12px;
  width: 210px;
  margin-left: 20px;
  padding: 5px 14px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.7);
  -webkit-box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.7);
}
.error-msg .icon {
  float: left;
  font-size: 30px;
  margin-top: 8px;
  width: 40px;
}
.error-msg .message {
  float: left;
  width: 130px;
}
.error-msg .message b {
  font-size: 18px;
  line-height: normal;
}
.error-msg:before {
  content: " ";
  display: block;
  position: absolute;
  right: -11px;
  top: 10px;
  width: 20px;
  height: 20px;
  border-color: #fff;
  border-width: 2px;
  border-style: solid solid none none;
  background-color: #ffb400;
  box-shadow: 2px -2px 3.5px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 2px -2px 3.5px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 2px -2px 3.5px rgba(0, 0, 0, 0.5);
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
}

.options-shown {
  background: rgba(0, 0, 0, 0.1);
  bottom: 0px;
  color: #fff;
  font-size: 10px;
  left: 0px;
  padding: 2px 6px;
  position: absolute;
}
.options-shown:before {
  content: "Options Shown";
}
.options-shown.es:before {
  content: "Se muestra con opciones.";
}

.options-shown-proto:before {
  content: "Prototype shown with options.";
}
.options-shown-proto.es:before {
  content: "Se muestra prototipo con opciones.";
}

.options-shown-prototype {
  background: rgba(0, 0, 0, 0.1);
  bottom: 0px;
  color: #fff;
  font-size: 10px;
  left: 0px;
  padding: 2px 6px;
  position: absolute;
}
.options-shown-prototype:before {
  content: "Prototype shown with options";
}
.options-shown-prototype.es:before {
  content: "Se muestra prototipo con opciones";
}

.top-photo {
  margin: 0 auto;
  position: relative;
  margin-top: 72px;
}
.top-photo .placeholder {
  vertical-align: top;
  opacity: 0;
  width: 100%;
}
.top-photo .search-box {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  margin-right: 40px;
  right: 0;
  z-index: 1;
  padding-top: 10px;
  padding-bottom: 5px;
}
.top-photo .search-title {
  color: #fff;
  font-size: 26px;
  font-size: 2.6rem;
  line-height: 30px;
  margin: 10px 10px 20px 10px;
  text-align: center;
}
.top-photo .search-reason {
  padding: 0 15px;
}
.top-photo .search-zip {
  padding: 0 15px 0 6px;
}
.top-photo .search-city {
  padding: 0 6px 0 15px;
}
.top-photo .search-state {
  padding: 0 15px 0 6px;
}
.top-photo .search-button button {
  font-size: 16px;
  padding: 6px 20px;
  width: 100%;
}
.top-photo .search-button i {
  font-size: 28px;
  line-height: 30px;
  margin-right: 6px;
  vertical-align: sub;
}
.top-photo .search-alt {
  font-size: 11px;
  margin: 10px 0;
  text-align: center;
}
.top-photo .search-alt a {
  color: #fff;
}

.top-photo.home1 {
  background: url(/rental/img/home/2018-Camry-1280x640.jpg) no-repeat center top;
  background-size: 100%;
}

.top-photo.home2 {
  background: url(/rental/img/home/2019-RAV4-1280x640.jpg) no-repeat center top;
  background-size: 100%;
}

.top-photo.home3 {
  background: url(/rental/img/home/2024-GrandHighlander-1440x500.jpg) no-repeat center top;
  background-size: 100%;
  max-width: 1440px;
}

.top-photo.home4 {
  background: url(/rental/img/home/home-4-1280.jpg) no-repeat center top;
  background-size: 100%;
}

.top-photo.home5 {
  background: url(/rental/img/home/home-5-1280.jpg) no-repeat center top;
  background-size: 100%;
}

.top-photo.home6 {
  background: url(/rental/img/home/home-6-1280.jpg) no-repeat center top;
  background-size: 100%;
}

.top-photo.home7 {
  background: url(/rental/img/home/be-there-trac-1280x640-2.jpg) no-repeat center top;
  background-size: 100%;
}

.home-tagline {
  padding-top: 120px;
  padding-left: 4%;
}
.home-tagline .home-title {
  font-size: 3.6rem;
  font-style: italic;
  float: left;
  line-height: 3.6rem;
  width: 360px;
}
.home-tagline .home-title b {
  color: #e10a1d;
}
.home-tagline .home-title-logo {
  float: left;
  margin-left: 20px;
}
.home-tagline .home-title-logo img {
  width: 100%;
}

.form-spacer {
  display: none;
}

.top-photo.yaris {
  background: url(/rental/img/vehicles/yaris-1280.jpg) no-repeat center top;
  background-size: 100%;
}

.top-photo.yaris-ia {
  background: url(/rental/img/vehicles/yaris-ia-1280.jpg) no-repeat center top;
  background-size: 100%;
}

.top-photo.corolla {
  background: url(/rental/img/vehicles/corolla-1280.jpg) no-repeat center top;
  background-size: 100%;
}

.top-photo.corolla-im {
  background: url(/rental/img/vehicles/corolla-im-1280.jpg) no-repeat center top;
  background-size: 100%;
}

.top-photo.camry {
  background: url(/rental/img/vehicles/camry-1280.jpg) no-repeat center top;
  background-size: 100%;
}

.top-photo.avalon {
  background: url(/rental/img/vehicles/avalon-1280.jpg) no-repeat center top;
  background-size: 100%;
}

.top-photo.eighty-six {
  background: url(/rental/img/vehicles/86-1280.jpg) no-repeat center top;
  background-size: 100%;
}

.top-photo.sienna {
  background: url(/rental/img/vehicles/sienna-1280.jpg) no-repeat center top;
  background-size: 100%;
}

.top-photo.tacoma {
  background: url(/rental/img/vehicles/tacoma-1280.jpg) no-repeat center top;
  background-size: 100%;
}

.top-photo.tundra {
  background: url(/rental/img/vehicles/tundra-1280.jpg) no-repeat center top;
  background-size: 100%;
}

.top-photo.rav4 {
  background: url(/rental/img/vehicles/rav4-1280.jpg) no-repeat center top;
  background-size: 100%;
}

.top-photo.c-hr {
  background: url(/rental/img/vehicles/chr-1280.jpg) no-repeat center top;
  background-size: 100%;
}

.top-photo.highlander {
  background: url(/rental/img/vehicles/highlander-1280.jpg) no-repeat center top;
  background-size: 100%;
}

.top-photo.fourrunner {
  background: url(/rental/img/vehicles/4runner-1280.jpg) no-repeat center top;
  background-size: 100%;
}

.top-photo.sequoia {
  background: url(/rental/img/vehicles/sequoia-1280.jpg) no-repeat center top;
  background-size: 100%;
}

.top-photo.landcruiser {
  background: url(/rental/img/vehicles/landcruiser-1280.jpg) no-repeat center top;
  background-size: 100%;
}

.top-photo.venza {
  background: url(/rental/img/vehicles/venza-1280.jpg) no-repeat center top;
  background-size: 100%;
}

.top-photo.priusprime {
  background: url(/rental/img/vehicles/priusprime-1280.jpg) no-repeat center top;
  background-size: 100%;
}

.top-photo.corollahatchback {
  background: url(/rental/img/vehicles/corollahatchback-1280.jpg) no-repeat center top;
  background-size: 100%;
}

.top-photo.prius {
  background: url(/rental/img/vehicles/prius-1280.jpg) no-repeat center top;
  background-size: 100%;
}

.top-photo.priusc {
  background: url(/rental/img/vehicles/priusc-1280.jpg) no-repeat center top;
  background-size: 100%;
}

.top-photo.priusv {
  background: url(/rental/img/vehicles/priusv-1280.jpg) no-repeat center top;
  background-size: 100%;
}

.top-photo.camry-hybrid {
  background: url(/rental/img/vehicles/camry-hybrid-1280.jpg) no-repeat center top;
  background-size: 100%;
}

.top-photo.avalon-hybrid {
  background: url(/rental/img/vehicles/avalon-hybrid-1280.jpg) no-repeat center top;
  background-size: 100%;
}

.top-photo.rav4-hybrid {
  background: url(/rental/img/vehicles/rav4-hybrid-1280.jpg) no-repeat center top;
  background-size: 100%;
}

.top-photo.highlander-hybrid {
  background: url(/rental/img/vehicles/highlander-hybrid-1280.jpg) no-repeat center top;
  background-size: 100%;
}

.top-photo.corolla-hybrid {
  background: url(/rental/img/vehicles/corolla-hybrid-1280.jpg) no-repeat center top;
  background-size: 100%;
}

.vehicle-top-photo .placeholder {
  vertical-align: top;
  opacity: 0;
  width: 100%;
}

.form-group {
  margin-bottom: 10px;
}
.form-group .panel-group {
  margin-bottom: 10px;
}

.icon-button {
  float: left;
}
.icon-button a {
  font-size: 14px;
  font-size: 1.4rem;
  padding: 8px 15px;
}
.icon-button button {
  font-size: 14px;
  font-size: 1.4rem;
  padding: 8px 15px;
}
.icon-button i {
  font-size: 22px;
  line-height: 22px;
  margin-right: 6px;
  vertical-align: sub;
}

.icon-button-lg a {
  font-size: 14px;
  padding: 19px 20px 18px;
}
.icon-button-lg i {
  font-size: 26px;
  line-height: 0px;
  margin-right: 6px;
  vertical-align: sub;
}

.vehicle-header {
  margin: 20px 0;
}

.vehicle-name {
  float: left;
  margin-right: 20px;
}

.vehicle-stats {
  float: left;
}

.vehicle-stat {
  float: left;
  border-left: 1px solid #000;
  line-height: 18px;
  padding: 9px 16px;
  text-align: center;
}
.vehicle-stat:nth-child(2n) {
  border-right: 1px solid #000;
}
.vehicle-stat .icon {
  color: #e10a1d;
  font-size: 20px;
}
.vehicle-stat .type {
  font-size: 14px;
  font-size: 1.4rem;
}
.vehicle-stat .type a {
  cursor: pointer;
}
.vehicle-stat .qty {
  font-family: "ToyotaFontSemiBold", Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-size: 2rem;
  font-weight: bold;
}

.vehicle-buttons {
  float: right;
  margin-top: 20px;
}
.vehicle-buttons .icon-button:nth-child(3n) {
  display: none;
}
.vehicle-buttons .icon-button a {
  margin-left: 10px;
}
.vehicle-buttons .icon-button button {
  margin-left: 10px;
}

.vehicle-intro {
  margin-bottom: 30px;
}
.vehicle-intro .popover {
  width: 240px;
}

.vehicle-body {
  background: #eeeeee;
  background: -moz-radial-gradient(center, ellipse cover, #eeeeee 0%, #ffffff 70%);
  background: -webkit-radial-gradient(center, ellipse cover, #eeeeee 0%, #ffffff 70%);
  background: radial-gradient(ellipse at center, #eeeeee 0%, #ffffff 70%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#eeeeee", endColorstr="#ffffff", GradientType=1);
  background-position-y: -140px;
  background-repeat: no-repeat;
  padding-bottom: 40px;
  border-bottom: 1px solid #dfdfdf;
}
.vehicle-body .popover {
  width: 240px;
}
.vehicle-body ul li sup a {
  cursor: pointer;
}

.vehicle-photo {
  float: right;
  margin: 10px 0 20px 40px;
  position: relative;
  width: 50%;
}
.vehicle-photo img {
  max-width: 100%;
}

.section-subtitle {
  margin: 20px 0;
  text-align: center;
}

.section {
  margin-bottom: 40px;
}
.section .section-inner-container {
  background-color: #edf0f5;
  text-align: center;
}
.section .section-inner-container .inner-panel .specials-subtitle {
  display: inline-block;
  margin: 30px 0 0;
  text-align: center;
}
.section .section-inner-container .inner-panel .specials-subtitle h3 {
  margin-bottom: 10px;
}
.section .section-inner-container .inner-panel-button {
  margin: 40px 0;
  text-align: center;
}
.section .section-inner-container .inner-panel-button .icon-button-lg {
  float: none;
}
.section .section-inner-container .awards-cards {
  margin: 0 auto;
  max-width: 1000px;
}

.local-special {
  margin: 0 10px;
}

.cash-offer {
  font-size: 14px;
  font-size: 1.4rem;
  border-top: 3px solid #ff8d40;
}
.cash-offer .offer-ribbon {
  padding: 10px 0 0;
  height: 40px;
  text-transform: uppercase;
  color: #ff8d40;
}

.lease-offer {
  font-size: 14px;
  font-size: 1.4rem;
  border-top: 3px solid #00d847;
}
.lease-offer .offer-ribbon {
  padding: 10px 0 0;
  height: 40px;
  text-transform: uppercase;
  color: #00d847;
}

.finance-offer {
  font-size: 14px;
  font-size: 1.4rem;
  border-top: 3px solid #009df5;
}
.finance-offer .offer-ribbon {
  padding: 10px 0 0;
  height: 40px;
  text-transform: uppercase;
  color: #009df5;
}

.misc-offer {
  font-size: 14px;
  font-size: 1.4rem;
  border-top: 3px solid #f20889;
}
.misc-offer .offer-ribbon {
  padding: 10px 0 0;
  height: 40px;
  text-transform: uppercase;
  color: #f20889;
}
.misc-offer .wrapper {
  height: 60px !important;
}
.misc-offer .one-column-centered {
  height: 140px !important;
}
.misc-offer .one-column-centered .one-column-offer {
  font-size: 14px !important;
  font-size: 1.4rem !important;
  line-height: 1.7rem !important;
}

.tcuv-offer {
  font-size: 14px;
  font-size: 1.4rem;
  border-top: 3px solid #f20889;
}
.tcuv-offer .offer-ribbon {
  padding: 10px 0 0;
  height: 40px;
  text-transform: uppercase;
  color: #cc0000;
}

.tcom-incentive-offer {
  position: relative;
  margin: 0 auto;
  background-color: #fff;
  text-align: left;
}
.tcom-incentive-offer .wrapper {
  height: 100px;
  padding: 0 20px;
}
.tcom-incentive-offer .wrapper .series-info {
  padding-bottom: 8px;
}
.tcom-incentive-offer .wrapper .series-info .standard-offer {
  font-size: 20px;
  font-size: 2rem;
  line-height: 2rem;
}
.tcom-incentive-offer .wrapper .series-info .standard-offer .series-id {
  font-family: "ToyotaFontSemiBold", Arial, Helvetica, sans-serif;
  font-weight: bold;
}
.tcom-incentive-offer .wrapper .series-info .standard-offer .series-trim {
  font-size: 12px;
  font-size: 1.2rem;
  height: 20px;
}
.tcom-incentive-offer .incentive-details {
  text-align: center;
  width: 100%;
}
.tcom-incentive-offer .one-column-centered {
  background-color: #f7f7f7;
  display: table;
  height: 100px;
  padding: 10px;
  width: 100%;
}
.tcom-incentive-offer .one-column-centered .one-column-offer {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  padding: 0;
  font-size: 1.8rem;
  line-height: 2rem;
}
.tcom-incentive-offer .one-column-centered .one-column-offer sup {
  font-size: 60%;
}
.tcom-incentive-offer .one-column-centered .one-column-offer .disclaimer {
  color: #666;
  font-size: 1.1rem;
}
.tcom-incentive-offer .two-column-offer {
  background-color: #f7f7f7;
  display: table;
  height: 100px;
  padding: 10px;
  width: 100%;
}
.tcom-incentive-offer .two-column-offer .two-column {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 40%;
  padding: 0;
  font-size: 2.2rem;
}
.tcom-incentive-offer .two-column-offer .two-column .apr-percent {
  font-size: 1.4rem;
  vertical-align: top;
}
.tcom-incentive-offer .two-column-offer .two-column .description {
  font-size: 0.8125rem;
  line-height: normal;
}
.tcom-incentive-offer .two-column-offer .three-column {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 30%;
  padding: 0;
  font-size: 2.2rem;
}
.tcom-incentive-offer .two-column-offer .three-column sup {
  font-size: 60%;
}
.tcom-incentive-offer .two-column-offer .three-column .description {
  font-size: 0.8125rem;
  line-height: normal;
}
.tcom-incentive-offer .three-column-offer {
  background-color: #f7f7f7;
  display: table;
  height: 100px;
  padding: 10px;
  width: 100%;
}
.tcom-incentive-offer .three-column-offer .two-column {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 40%;
  padding: 0;
  font-size: 2.2rem;
}
.tcom-incentive-offer .three-column-offer .two-column .apr-percent {
  font-size: 1.4rem;
  vertical-align: top;
}
.tcom-incentive-offer .three-column-offer .two-column .description {
  font-size: 0.8125rem;
  line-height: normal;
}
.tcom-incentive-offer .three-column-offer .three-column {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 30%;
  padding: 0;
  font-size: 2.2rem;
}
.tcom-incentive-offer .three-column-offer .three-column sup {
  font-size: 60%;
}
.tcom-incentive-offer .three-column-offer .three-column .description {
  font-size: 0.8125rem;
  line-height: normal;
}
.tcom-incentive-offer .details-link {
  background: #fff;
  padding: 10px;
  color: #c00;
  font-size: 14px;
  text-transform: uppercase;
}

.slp-disclaimer {
  padding-top: 15px;
  color: #666;
  font-size: 1.1rem;
}

.kbb-card {
  background: #3166b4;
  color: #fff;
  margin: 40px 0 0;
  padding: 0 0 20px;
  text-align: center;
}
.kbb-card .wrapper {
  display: none;
  padding: 20px 0 0;
}
.kbb-card .wrapper h4 {
  font-weight: normal;
  font-size: 20px;
  font-size: 2rem;
}
.kbb-card .wrapper p {
  margin: 10px 0;
}
.kbb-card .wrapper a {
  color: #fff;
}
.kbb-card .wrapper-on {
  display: block;
}
.kbb-card .subhead {
  font-size: 12px;
  font-size: 1.2rem;
}
.kbb-card .ratings {
  border-bottom: 1px solid #fff;
  margin-bottom: 20px;
  padding: 10px 0 50px 0;
}
.kbb-card .ratings .overall .rating-label {
  font-size: 12px;
  font-size: 1.2rem;
  font-weight: normal;
}
.kbb-card .ratings .overall .rating {
  font-size: 50px;
  font-size: 5rem;
  line-height: 50px;
}
.kbb-card .ratings .overall .max {
  color: rgba(255, 255, 255, 0.6);
}
.kbb-card .ratings .individual {
  margin: 20px auto 0;
  padding: 0 20px;
}
.kbb-card .ratings .individual .rating {
  font-size: 20px;
  font-size: 2rem;
  line-height: 24px;
  display: inline-block;
  min-width: 50px;
  margin-right: 10px;
}
.kbb-card .ratings .individual .rating:nth-child(3) {
  margin: 0;
}
.kbb-card .ratings .individual .rating .rating-label {
  font-family: "ToyotaFontSemiBold", Arial, Helvetica, sans-serif;
  font-size: 13px;
  font-size: 1.3rem;
  font-weight: bold;
  display: block;
}
.kbb-card .ratings .individual .three-group {
  padding: 0;
}
.kbb-card .ratings .disclaimer {
  color: rgba(255, 255, 255, 0.6);
  font-size: 11px;
  font-size: 1.1rem;
}

.edmunds-card {
  background: #fff;
  color: #333;
  margin: 40px 0 0;
  padding: 38px 10px 20px;
  text-align: center;
}
.edmunds-card .wrapper {
  padding: 27px 0 0;
}
.edmunds-card .wrapper h4 {
  font-weight: normal;
  font-size: 20px;
  font-size: 2rem;
}
.edmunds-card .wrapper p {
  margin: 10px 0;
}
.edmunds-card .wrapper a {
  color: #333;
}
.edmunds-card .subhead {
  font-size: 12px;
  font-size: 1.2rem;
}
.edmunds-card .ratings {
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
  padding: 10px 0 53px 0;
}
.edmunds-card .ratings .overall .rating-label {
  font-size: 12px;
  font-size: 1.2rem;
}
.edmunds-card .ratings .overall .rating {
  font-size: 50px;
  font-size: 5rem;
  line-height: 50px;
}
.edmunds-card .ratings .overall .max {
  color: #ccc;
}
.edmunds-card .ratings .stars {
  margin-top: 10px;
}
.edmunds-card .ratings .stars span svg {
  width: 50px;
  height: 50px;
}
.edmunds-card .ratings .disclaimer {
  color: #ccc;
  font-size: 11px;
  font-size: 1.1rem;
  margin: 8px 0 9px;
}

.bottom-button {
  margin: 40px 0;
}

hr.fancy-line {
  border: 0;
  height: 1px;
  position: relative;
  margin: 0 0 40px 0;
  background: -moz-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 75%);
  background: -webkit-gradient(radial, center center, 0px, center center, 75%, color-stop(0%, rgba(0, 0, 0, 0.2)), color-stop(75%, rgba(0, 0, 0, 0)));
  background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 75%);
  background: -o-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 75%);
  background: -ms-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 75%);
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 75%);
}
hr.fancy-line:before {
  top: 0em;
  height: 1.5em;
  content: "";
  position: absolute;
  width: 100%;
  background: -moz-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 75%);
  background: -webkit-gradient(radial, center center, 0px, center center, 75%, color-stop(0%, rgba(0, 0, 0, 0.2)), color-stop(75%, rgba(0, 0, 0, 0)));
  background: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 75%);
  background: -o-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 75%);
  background: -ms-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 75%);
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 75%);
}
hr.fancy-line:after {
  content: "";
  height: 0.7em;
  top: 0px;
  position: absolute;
  width: 100%;
  background: #fff;
}

.content-page-title {
  font-size: 40px;
  position: absolute;
  bottom: 20px;
}
.content-page-title h1 {
  color: #fff;
}

.top-photo.about {
  background: url(/rental/img/about-1280-2019.jpg) no-repeat center top;
  background-size: 100%;
}
.top-photo.about .placeholder {
  vertical-align: top;
  opacity: 0;
  width: 100%;
}

.about-content {
  margin: 40px 0;
}
.about-content p {
  margin: 20px 0;
}
.about-content .logo-graphic {
  float: right;
  margin-left: 40px;
}

.info-boxes .info-box {
  padding: 1px;
}

figure {
  display: block;
  position: relative;
  float: left;
  overflow: hidden;
  margin: 0;
  width: 100%;
}
figure:hover {
  cursor: context-menu;
}
figure:hover figcaption {
  opacity: 1;
}
figure:hover:before {
  opacity: 0;
}
figure:before {
  font-family: "ToyotaFontSemiBold", Arial, Helvetica, sans-serif;
  position: absolute;
  font-weight: bold;
  background: #e10a1d;
  color: #fff;
  height: 24px;
  font-size: 18px;
  font-size: 1.8rem;
  transition: all 0.1s ease;
  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  -o-transition: all 0.1s ease;
  opacity: 1;
}

figcaption {
  position: absolute;
  background: #a9141a;
  color: white;
  padding: 10px 20px;
  opacity: 0;
  transition: all 0.1s ease;
  -webkit-transition: all 0.1s ease;
  -moz-transition: all 0.1s ease;
  -o-transition: all 0.1s ease;
}
figcaption .caption {
  color: #fff;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 2rem;
}
figcaption .title {
  font-family: "ToyotaFontSemiBold", Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 10px;
}

figure.about1:before {
  content: "Business/Leisure Travel Rentals";
}
figure.about1.es:before {
  content: "Alquiler de vacaciones o por viaje de negocios";
}
figure.about1 .reason-image {
  background: url(/rental/img/home/reason-1-480.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 0;
  padding-top: 56.5%;
}

figure.about2 .reason-image {
  background: url(/rental/img/home/reason-2-480.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 0;
  padding-top: 56.5%;
}
figure.about2:before {
  content: "Try Before You Buy";
}
figure.about2.es:before {
  content: "Prueba antes de comprar";
}

figure.about3 .reason-image {
  background: url(/rental/img/home/reason-3-480.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 0;
  padding-top: 56.5%;
}
figure.about3:before {
  content: "Service Rentals";
}
figure.about3.es:before {
  content: "Alquiler por mantenimiento";
}

figure.about4 .reason-image {
  background: url(/rental/img/home/reason-4-480.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 0;
  padding-top: 56.5%;
}
figure.about4:before {
  content: "Body Shop Rentals";
}
figure.about4.es:before {
  content: "Alquiler por servicio de arreglos";
}

.cap-bot img {
  width: 100%;
  max-width: 100%;
}
.cap-bot:before {
  bottom: 0;
  height: 44px;
  left: 0;
  padding: 10px 20px;
  width: 100%;
}
.cap-bot figcaption {
  bottom: -30%;
  left: 0;
}
.cap-bot:hover figcaption {
  bottom: 0;
}

.top-photo.faq {
  background: url(/rental/img/faq-1280.jpg) no-repeat center top;
  background-size: 100%;
}
.top-photo.faq .placeholder {
  vertical-align: top;
  opacity: 0;
  width: 100%;
}

.faq-content {
  margin: 40px 0;
}
.faq-content ul {
  margin-left: 15px;
}
.faq-content b {
  color: #e10a1d;
  font-size: 2rem;
}
.faq-content p {
  margin: 20px 0;
}
.faq-content .add-on {
  margin-top: 50px;
}

ul.no-bullets {
  list-style: none;
  margin-left: 0;
}

strong {
  font-family: "ToyotaFontSemiBold", Arial, Helvetica, sans-serif;
  font-size: 1.6rem;
}
strong.red-bold {
  color: #e10a1d;
}

.toyota-app-warn b {
  color: #e10a1d;
  font-size: 1.6rem;
}

.top-photo.cartips {
  background: url(/rental/img/cartips-1280.jpg) no-repeat center top;
  background-size: 100%;
}
.top-photo.cartips .placeholder {
  vertical-align: top;
  opacity: 0;
  width: 100%;
}

.cartips-content {
  margin: 40px 0;
}
.cartips-content ol li {
  margin: 0 0 15px 0px;
}
.cartips-content ul li {
  margin: 0 0 15px 20px;
}
.cartips-content a.list-group-item {
  color: #e10a1d;
}
.cartips-content button.list-group-item {
  color: #e10a1d;
}
.cartips-content .list-group-item {
  border-radius: 0px;
  padding: 15px 20px;
}
.cartips-content .list-group-item.active {
  z-index: 2;
  color: #000;
  background-color: #eee;
  border: #ddd;
}
.cartips-content .list-group-item.active:focus {
  z-index: 2;
  color: #000;
  background-color: #eee;
  border: #ddd;
}
.cartips-content .list-group-item.active:hover {
  z-index: 2;
  color: #000;
  background-color: #eee;
  border: #ddd;
}
.cartips-content .list-group-item.disabled {
  cursor: context-menu;
}
.cartips-content .list-group-item.disabled:focus {
  cursor: context-menu;
}
.cartips-content .list-group-item.disabled:hover {
  cursor: context-menu;
}
.cartips-content p {
  margin: 20px 0;
}
.cartips-content .cartips-nav {
  margin-bottom: 20px;
}

.dealers-con {
  display: flex;
  border-bottom: 1px solid #d8d8d8;
}

.dealers-con.disabled {
  display: none;
}

.dealer-results {
  border-top: 1px solid #d8d8d8;
  margin-bottom: 0;
}
.dealer-results .dealer-map {
  flex: 1;
  height: 650px;
}
.dealer-results .content-page-title {
  border-top: 1px solid #ccc;
  padding: 30px 0;
  position: inherit;
}
.dealer-results .dealer-result {
  display: flex;
  margin: 0 0 40px 0;
  position: relative;
}
.dealer-results .dealer-result .result-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-basis: 75%;
}
.dealer-results .dealer-result .result-container .result-header .dealer {
  color: #000;
  font-size: 12px;
  float: left;
  margin: 2px 10px 8px 0;
  line-height: 1.7;
}
.dealer-results .dealer-result .result-container .result-header .dealer .leadership-award {
  display: flex;
  flex-direction: row;
  margin: 5px 0 10px 0;
}
.dealer-results .dealer-result .result-container .result-header .dealer .leadership-award img {
  position: relative;
  top: 3px;
  width: 17px;
  height: 16px;
  margin-right: 5px;
}
.dealer-results .dealer-result .result-container .result-header .dealer .distance {
  margin-top: 10px;
}
.dealer-results .dealer-result .result-container .result-header .dealer h4 {
  color: #000;
  font-size: 15px;
}
.dealer-results .dealer-result .result-container .result-header .award {
  float: left;
  margin: 2px 0 6px 10px;
}
.dealer-results .dealer-result .result-container .result-body .reservation-btn a {
  font-family: "ToyotaFontSemiBold", Arial, Helvetica, sans-serif;
  width: 100%;
  padding: 19px 20px 18px;
}
.dealer-results .dealer-result .result-container .result-body .dealer-contact-info .address {
  font-family: "ToyotaFontSemiBold", Arial, Helvetica, sans-serif;
  font-weight: bold;
  margin-bottom: 10px;
}
.dealer-results .dealer-result .result-container .result-body .dealer-contact-info .address .icon {
  float: left;
  margin-right: 10px;
}
.dealer-results .dealer-result .result-container .result-body .dealer-contact-info .address .icon .icon-directions {
  background: url(/rental/img/directions-btn.png) no-repeat;
  float: left;
  height: 20px;
  width: 20px;
  background-size: 80%;
  margin: 4px 0 0 0;
}
.dealer-results .dealer-result .result-container .result-body .services .service {
  font-size: 12px;
  line-height: 1.5;
}
.dealer-results .dealer-result .result-container .result-body .services .service i {
  color: #00a753;
  margin-right: 2px;
}
.dealer-results .dealer-result .result-container .result-body .services .service.not-available {
  color: #ccc;
  display: none;
}
.dealer-results .dealer-result .result-container .result-body .services .service.not-available i {
  color: #ccc;
  margin-right: 2px;
}
.dealer-results .dealer-result .result-container .result-body .services .service.not-available i.fa-check:before {
  content: "\f057";
}
.dealer-results .dealer-result .result-container .result-footer {
  font-family: "ToyotaFontSemiBold", Arial, Helvetica, sans-serif;
  border-top: 1px solid #d8d8d8;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
}
.dealer-results .dealer-result .result-container .result-footer .a-pipe {
  color: #d8d8d8;
}
.dealer-results .dealer-result .result-container .result-footer a {
  font-size: 12px;
}
.dealer-results .dealer-result .result-container .result-footer div a i {
  width: 20px;
  text-align: center;
}
.dealer-results .dealer-result .result-container .result-footer .icon-directions {
  background: url(/rental/img/directions-btn.png) no-repeat;
  float: left;
  height: 20px;
  width: 20px;
  background-size: 80%;
  margin: 2px 4px 0 0;
}
.dealer-results .dealer-result .result-container .result-footer .icon-left {
  float: left;
  margin: 0 10px;
}
.dealer-results .dealer-result .result-container .result-footer .icon-right {
  float: right;
  margin: 0 10px;
}
.dealer-results .result-num {
  position: relative;
  width: 40px;
}
.dealer-results .result-num img {
  width: 30px;
}
.dealer-results .result-num .num {
  position: absolute;
  color: #767676;
  font-size: 12px;
  text-align: center;
  width: 30px;
  top: 5px;
  left: 0;
}
.dealer-results .result-num.active .num {
  color: #e10a1d;
}

.dealers-panel {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  min-height: 100px;
}
.dealers-panel .dealer-panel-head {
  font-size: 12px;
  color: #000;
  margin: 25px 0;
}

.marker-con {
  position: relative;
}

.map-marker-label {
  position: absolute;
  font-family: "ToyotaFontSemiBold", Arial, Helvetica, sans-serif;
  text-align: center;
  width: 40px;
  top: 7px;
  left: 0;
  font-size: 15px;
  color: #767676;
}

.map-marker-label.active {
  color: #e10a1d;
}

.info_content {
  padding: 10px;
}
.info_content h3 {
  font-family: "ToyotaFontSemiBold", Arial, Helvetica, sans-serif;
  font-size: 15px;
}
.info_content p {
  font-family: "ToyotaFontRegular", Arial, Helvetica, sans-serif;
  font-size: 12px;
}
.info_content a {
  color: #e10a1d;
  font-family: "ToyotaFontRegular", Arial, Helvetica, sans-serif;
  font-size: 12px;
  margin-left: 1px;
}

.dealer-search {
  margin-top: 80px;
}
.dealer-search .content-page-title {
  position: inherit;
  margin-left: 0;
}
.dealer-search .content-page-title h1 {
  color: #000;
  font-size: 40px;
  line-height: 1.1;
}
.dealer-search .dealer-search-content {
  margin-top: 10px;
}
.dealer-search .dealer-search-content .search-types {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #d8d8d8;
}
.dealer-search .dealer-search-content .search-types .col {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}
.dealer-search .dealer-search-content .search-types .col:first-child {
  border-right: 1px solid #d8d8d8;
}
.dealer-search .dealer-search-content .search-types .col .search-type {
  padding: 16px 20px 15px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.dealer-search .dealer-search-content .search-types .col .search-type:focus {
  outline: 1px solid steelblue !important;
  outline-offset: -1px;
}
.dealer-search .dealer-search-content .search-types .col .search-type span {
  font-family: "ToyotaFontSemiBold", Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: #000;
}
.dealer-search .dealer-search-content .search-types .col .search-type img {
  align-self: center;
}
.dealer-search .dealer-search-content .search-types .col .search-type .red {
  display: flex;
}
.dealer-search .dealer-search-content .search-types .col .search-type .gray {
  display: none;
}
.dealer-search .dealer-search-content .search-types .col .search-type.active span {
  color: #717171;
}
.dealer-search .dealer-search-content .search-types .col .search-type.active .red {
  display: none;
}
.dealer-search .dealer-search-content .search-types .col .search-type.active .gray {
  display: flex;
}
.dealer-search .dealer-search-content .subtitle {
  text-align: center;
}
.dealer-search .dealer-search-content .reason-tiles {
  margin: 20px 0 40px;
}
.dealer-search .dealer-search-content .reason-tiles .reason-tile-container {
  padding: 3px;
}
.dealer-search .dealer-search-content .reason-tiles .reason-tile-container .reason-tile {
  background: none;
  padding: 4px;
  position: relative;
  -webkit-box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.6);
  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.6);
  width: 100%;
}
.dealer-search .dealer-search-content .reason-tiles .reason-tile-container .reason-tile img {
  width: 100%;
  max-width: 100%;
}
.dealer-search .dealer-search-content .reason-tiles .reason-tile-container .reason-tile .reason-selected {
  display: none;
  background: green;
  border-radius: 15px;
  color: #fff;
  height: 30px;
  padding-top: 4px;
  position: absolute;
  right: 10px;
  text-align: center;
  top: 10px;
  width: 30px;
  z-index: 1;
}
.dealer-search .dealer-search-content .reason-tiles .reason-tile-container .reason-tile .reason {
  background: #e10a1d;
  color: #fff;
  font-size: 16px;
  font-size: 1.6rem;
  padding: 6px 10px;
}
.dealer-search .dealer-search-content .reason-tiles .reason-tile-container .reason-tile.active .reason {
  background: #a9141a;
}
.dealer-search .dealer-search-content .reason-tiles .reason-tile-container .reason-tile.active .reason-selected {
  display: block;
}
.dealer-search .dealer-search-content .dealer-form.mobile-view {
  padding: 0 30px;
}
.dealer-search .dealer-search-content .dealer-form.mobile-view .nav-tabs {
  display: none;
  justify-content: space-evenly;
  border: none;
  padding: 10px 0 0 0;
}
.dealer-search .dealer-search-content .dealer-form.mobile-view .nav-tabs li.active a {
  border: none;
  text-decoration: underline;
}
.dealer-search .dealer-search-content .dealer-form.mobile-view .nav-tabs.active {
  display: flex;
}
.dealer-search .dealer-search-content .dealer-form .row {
  position: relative;
  display: flex;
}
.dealer-search .dealer-search-content .dealer-form div.col {
  position: relative;
  padding: 20px 0 10px 0;
}
.dealer-search .dealer-search-content .dealer-form div.col:nth-child(2) {
  margin: 0 30px;
}
.dealer-search .dealer-search-content .dealer-form .form-group {
  padding: 0 0;
}
.dealer-search .dealer-search-content .dealer-form .form-group .mobsel {
  position: absolute;
  right: 14px;
  top: 10px;
  display: inline-block;
}
.dealer-search .dealer-search-content .dealer-form .form-group.mobstate {
  position: relative;
}
.dealer-search .dealer-search-content .dealer-form .form-button {
  margin: 30px 0 40px;
  text-align: center;
}
.dealer-search .dealer-search-content .dealer-form ul {
  padding: 0;
}
.dealer-search .dealer-search-content .dealer-form ul li a {
  font-size: 12px;
  font-size: 1.2rem;
  padding: 10px;
}
.dealer-search .dealer-search-content .dealer-form ul li a:hover {
  background-color: #fff;
  text-decoration: underline;
  color: #a9141a;
}
.dealer-search .dealer-search-content .dealer-form ul li a:focus {
  background-color: #fff;
  text-decoration: underline;
  color: #a9141a;
}
.dealer-search .dealer-search-content .dealer-form ul li.active a {
  color: #717171;
}
.dealer-search .dealer-search-content .dealer-form .tab-content {
  display: none;
  padding: 30px 0 0;
}
.dealer-search .dealer-search-content .dealer-form .tab-content.active {
  display: block;
}
.dealer-search .dealer-search-content .dealer-form .nav-tabs {
  text-align: center;
}
.dealer-search .dealer-search-content .dealer-form .nav-tabs > li {
  float: none;
  display: inline-block;
  zoom: 1;
}
.dealer-search .dealer-search-content .dealer-form .nav-pills {
  text-align: center;
}
.dealer-search .dealer-search-content .dealer-form .nav-pills > li {
  float: none;
  display: inline-block;
  zoom: 1;
}

.dealer-search.fad {
  margin-top: 62px;
}
@media (min-width: 992px) {
  .dealer-search.fad {
    margin-top: 140px;
  }
}

.form-control.mobstate {
  padding: 10px 10px 7.5px 17px;
  height: 44px;
  color: #000;
  font-size: 14px !important;
}

.spinner-con {
  display: flex;
  justify-content: center;
  margin: 150px auto;
}

.spinner-con.disabled {
  display: none;
}

.spinner {
  margin: 0 45%;
  animation: rotator 1.4s linear infinite;
}

.path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
}

.panel-default > a > .panel-heading {
  color: #333;
  background-color: #f6f6f6;
  border-color: #ddd;
}

.highlight {
  display: inline-block;
  background: #fff;
  padding: 0;
  cursor: pointer;
}
.highlight img {
  display: block;
  -webkit-transition: all 0s linear;
  -moz-transition: all 0s linear;
  -ms-transition: all 0s linear;
  -o-transition: all 0s linear;
  transition: all 0s linear;
}
.highlight:hover img {
  opacity: 0.6;
}

.map-overlay {
  background: transparent;
  position: relative;
  width: 100%;
  height: 400px;
  top: 400px;
  margin-top: -400px;
}

.error-page-padding {
  padding: 100px 0 300px;
}

.sitemap {
  padding: 40px 0;
}
.sitemap h3 {
  margin: 10px 0;
}
.sitemap ul {
  list-style-type: none;
  padding: 0;
  margin: 0 0 20px;
}

.modal-header {
  border-bottom: none;
}
.modal-header .close {
  font-size: 40px;
  color: #000;
  opacity: 1;
  position: relative;
  top: -10px;
}
.modal-header .close:hover {
  color: #e10a1d;
  filter: alpha(opacity=100);
  opacity: 1;
}
.modal-header .close:focus {
  border: 1px solid steelblue !important;
  outline: none;
}

.modal-response {
  padding: 20px 60px 60px;
}

.modal-dialog.error {
  max-width: 500px;
  text-align: center;
  margin: 28px auto;
}
.modal-dialog.error p {
  font-size: 14px;
  margin-bottom: 20px;
}
.modal-dialog.error .text-danger {
  color: #e10a1d;
  font-size: 24px;
  margin-bottom: 20px;
}
.modal-dialog.error .modal-body {
  padding: 0 24px 18px 24px;
  margin-top: 18px;
}

.map-modal .modal-dialog {
  height: 97%;
}
.map-modal .modal-content {
  height: 97%;
}
.map-modal .modal-body {
  height: 100%;
  width: 100%;
  max-height: calc(100% - 34px);
  overflow-y: scroll;
}
.map-modal .modal-body iframe {
  height: 100%;
  width: 100%;
}

.modal-content {
  border-radius: 0;
}

.zip-modal {
  text-align: center;
}
.zip-modal .close:focus {
  outline: 1px solid steelblue !important;
  outline-offset: 2px;
}
.zip-modal .reg-modal-title {
  font-family: "ToyotaFontRegular", Arial, Helvetica, sans-serif;
  font-size: 32px;
  margin-top: 30px;
  margin-bottom: 12px;
  color: #000;
  z-index: 1001;
}
.zip-modal .modal-body {
  margin: 40px 0 65px 0;
}
.zip-modal p {
  position: relative;
  margin: 0 auto;
  color: #000;
  font-size: 14px;
  line-height: 1.2;
  padding-bottom: 10px;
}
.zip-modal input {
  width: 200px;
}

.zip-input-btn {
  margin-top: 10px;
  width: 135px;
  padding: 19px 20px 18px;
}

.zip-input-btn.disabled {
  background-color: #717171;
  border-color: #717171;
  pointer-events: none;
}

.field-con {
  margin: 0;
  width: 100%;
  position: relative;
}

.zip-modal-form-con {
  position: relative;
  margin: 0 auto;
  width: 202px;
}

.inline-error {
  position: relative;
  top: 7px;
  display: none;
  color: #e10a1d;
  font-size: 12px;
}

.inline-error.zipmodal {
  top: -18px;
}

.inline-error.abs {
  position: absolute;
  top: 45px;
}
.inline-error.abs.cad {
  top: 52px;
}

.inline-error.active {
  display: block;
}

.form-group-inline {
  display: inline-block;
  padding: 0;
  width: 100%;
}
.form-group-inline select {
  margin: 0;
  display: inline-block;
  position: relative;
  top: -2px;
  height: 42px;
  width: 100px;
  padding: 6px 8px 6px 15px;
}
.form-group-inline .rt-select.error select {
  border: 1px solid #e10a1d;
}

.form-group-inline.ml-30 {
  margin-left: 30px;
}

.form-group-inline.mr-15 {
  margin-right: 15px;
}

.rt-select {
  display: inline-block;
}
.rt-select .select-arrow {
  display: inline-block;
  cursor: pointer;
}
.rt-select .select-arrow:before {
  display: none;
}
.rt-select .select-arrow img {
  top: -3px;
  left: -26px;
  position: relative;
}
.rt-select .state1-select {
  cursor: pointer;
}
.rt-select .state2-select {
  cursor: pointer;
}
.rt-select.es select {
  font-size: 12px;
}

.col-divider-pipe {
  position: absolute;
  top: 20px;
  display: inline-block;
  height: 43px;
  width: 2px;
  background-color: #d8d8d8;
}

.tcuv-form-field {
  display: block;
  margin: 0 auto 22px auto;
}

.tcuv-form-field.active .tcuv-form-field-label {
  color: #303841;
  background-color: #fff;
  padding: 5px 10px;
  font-size: 12px;
  transform: translateY(-11px);
  left: 10px;
}
.tcuv-form-field.active .tcuv-form-field-label.cad {
  transform: translateY(-18px);
}

.tcuv-form-field-label {
  display: block;
  pointer-events: none;
  color: #717171;
  font-size: 14px;
  font-weight: normal;
  left: 0;
  margin: 0;
  padding: 15px 12px 0;
  position: absolute;
  top: -5px;
  transition: all 0.4s;
  width: auto;
}
.tcuv-form-field-label.es {
  font-size: 12px;
}
.tcuv-form-field-label.cad {
  top: -1px;
}

.tcuv-form-field-control {
  border: 1px solid transparent;
  position: relative;
  width: 100%;
}
.tcuv-form-field-control::after {
  border-bottom: 2px solid #b11adc;
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  transform: scaleX(0);
  transition: all 0.4s;
  width: 1%;
}
.tcuv-form-field-control.cad {
  width: 100%;
}

.tcuv-form-field-control.error {
  border: 1px solid #e10a1d;
}
.tcuv-form-field-control.error input:focus {
  outline: green;
  outline-offset: 2px;
}
.tcuv-form-field-control.error:focus-within {
  border: 1px solid #d8d8d8 !important;
}

.tcuv-form-field-input {
  color: #000;
  width: 100%;
  padding: 10px 10px 7.5px 17px;
  border: 1px solid #717171;
}

.tcuv-form-field-textarea {
  appearance: none;
  background: transparent;
  border: 0;
  color: #000;
  display: block;
  font-size: 14px;
  margin-top: 15px;
  outline: 0;
  padding: 0 12px 10px 12px;
  width: 100%;
  height: 150px;
}

.btn-primary.submitMobile {
  display: none;
  padding: 19px 40px 18px;
  width: 100%;
  margin: 5px 0 15px 0;
  justify-content: center;
}

.btn-primary.submitMobile.active {
  display: flex;
}

.btn-primary.submitDesktop {
  display: none;
}

.reason-checks {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  align-items: center;
}

.reason-checks-mobile {
  display: none;
  flex-direction: column;
  margin: 25px 0 0 0;
}
.reason-checks-mobile .checkbox {
  margin-bottom: 25px;
}

.reason-checks-mobile.active {
  display: flex;
}

.checkbox2 + .checkbox2 {
  margin-top: 0;
}

.radio + .radio {
  margin-top: 0;
}

.checkbox2 {
  margin-top: 0;
  margin-bottom: 0;
  display: inline;
  margin-right: 40px;
  cursor: pointer;
}
.checkbox2:disabled {
  pointer-events: none;
}
.checkbox2:disabled label {
  color: #7a7a7a;
}
.checkbox2 input[type=checkbox] {
  margin-top: 0;
  opacity: 0;
}
.checkbox2 input[type=checkbox]:checked {
  background-color: #e10a1d;
}
.checkbox2 input[type=checkbox]:checked + label::before {
  border: 1px solid transparent;
  background-color: #e10a1d;
}
.checkbox2 input[type=checkbox]:checked + label::after {
  content: "";
}
.checkbox2 input[type=checkbox] + label {
  cursor: pointer;
}
.checkbox2 input[type=checkbox] + label::after {
  content: none;
}
.checkbox2 input[type=checkbox]:focus + label::before {
  outline: 2px solid steelblue !important;
}
.checkbox2 label {
  font-size: 12px;
  position: relative;
  display: inline-block;
  padding-left: 34px;
}
.checkbox2 label::before {
  position: absolute;
  content: "";
  display: inline-block;
  height: 24px;
  width: 24px;
  border: 1px solid #717171;
  left: 0px;
  top: -1px;
}
.checkbox2 label::after {
  position: absolute;
  content: "";
  display: inline-block;
  height: 6px;
  width: 10px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(-45deg);
  left: 7px;
  top: 7px;
}

.reasonCheckMob {
  position: relative;
}

.reasonCheckMob label {
  display: block;
  padding: 12px 0 12px 48px;
  font-size: 15px;
}

.reasonCheckMob input {
  height: 40px;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 40px;
}

.reasonCheckMob input + label::before {
  border: 2px solid #717171;
  content: "";
  height: 40px;
  left: 0;
  position: absolute;
  top: 0;
  width: 40px;
}

.reasonCheckMob input + label::after {
  content: "";
  border: 3px solid;
  border-left: 0;
  border-top: 0;
  height: 20px;
  left: 14px;
  opacity: 0;
  position: absolute;
  top: 6px;
  transform: rotate(45deg);
  transition: opacity 0.2s ease-in-out;
  width: 12px;
  color: #fff;
}

.reasonCheckMob input:checked + label::after {
  opacity: 1;
}

.reasonCheckMob input:checked + label::before {
  opacity: 1;
  background-color: #e10a1d;
}

.reasonCheckMob input:focus + label::before {
  outline: 1px solid steelblue !important;
  outline-offset: 2px;
}

.radio {
  margin-top: 0;
  margin-bottom: 0;
}

.contact-modal .modal-body {
  margin-top: 15px;
}
.contact-modal .form-container {
  margin: 20px 0;
}
.contact-modal .form-container .contact-via {
  font-size: 14px;
}
.contact-modal .form-container .contact-via input {
  margin-left: 6px;
}
.contact-modal .form-container .icon-button-lg a {
  width: 100%;
}
.contact-modal .date-time .form-group {
  float: left;
  width: 48%;
}
.contact-modal .date-time .form-group:nth-child(even) {
  float: right;
}
.contact-modal h3 {
  border-bottom: 4px solid #e10a1d;
}
.contact-modal .panel-title a {
  color: #333;
}
.contact-modal .panel-title input {
  margin-right: 6px;
}
.contact-modal .panel-title i.indicator {
  font-size: 20px;
}
.contact-modal .panel-body {
  font-size: 14px;
  font-size: 1.4rem;
}
.contact-modal .panel-body div {
  display: inline-block;
  margin: 1px 0;
  padding: 0;
  width: 110px;
}
.contact-modal .panel-body.hybrids-panel .checkbox:last-child {
  width: 150px;
}

.cd-top {
  display: inline-block;
  height: 40px;
  width: 40px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  background: #e10a1d url(/rental/img/cd-top-arrow.svg) no-repeat center 50%;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.3s 0s, visibility 0s 0.3s;
  -moz-transition: opacity 0.3s 0s, visibility 0s 0.3s;
  transition: opacity 0.3s 0s, visibility 0s 0.3s;
  border-radius: 10px;
  z-index: 10;
}
.cd-top:hover {
  background-color: #a9141a;
  opacity: 1;
}

.cd-top.cd-is-visible {
  -webkit-transition: opacity 0.3s 0s, visibility 0s 0s;
  -moz-transition: opacity 0.3s 0s, visibility 0s 0s;
  transition: opacity 0.3s 0s, visibility 0s 0s;
  visibility: visible;
  opacity: 1;
}

.cd-top.cd-fade-out {
  -webkit-transition: opacity 0.3s 0s, visibility 0s 0s;
  -moz-transition: opacity 0.3s 0s, visibility 0s 0s;
  transition: opacity 0.3s 0s, visibility 0s 0s;
  opacity: 0.75;
}

.no-touch .cd-top:hover {
  -webkit-transition: opacity 0.3s 0s, visibility 0s 0s;
  -moz-transition: opacity 0.3s 0s, visibility 0s 0s;
  transition: opacity 0.3s 0s, visibility 0s 0s;
  background-color: #a9141a;
  opacity: 1;
}

.ui-widget {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  font-size: 1.4rem !important;
}

.ui-datepicker table {
  font-size: 0.7em;
}

.help-block {
  color: #e10a1d;
  display: none;
  font-size: 14px;
  font-size: 1.4rem;
}

.has-error .form-control {
  border-color: #e10a1d;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-error .help-block {
  color: #e10a1d;
  display: block;
}
.has-error .help-block i {
  margin-right: 4px;
}

.modal-open .modal {
  margin: 0 auto;
}

.ensCenter {
  font-size: 14px !important;
}

@media (min-width: 1200px) {
  .container.nav-con {
    height: 81px;
    width: 980px;
  }
  .navbar-inverse #menuTabs .tab-content .tab-pane {
    margin: 0 auto;
    width: 1170px;
    max-width: 1170px;
  }
  .navbar-inverse .nav-tabs {
    margin: 25px auto 0 auto;
    width: 1170px;
  }
}
@media (max-width: 480px) {
  input {
    font-size: 16px !important;
  }
  select {
    font-size: 16px !important;
  }
  textarea {
    font-size: 16px !important;
  }
  h1 {
    font-size: 32px;
    font-size: 2.8rem;
  }
  h2.car-tips-h2 {
    font-size: 32px;
    font-size: 2.8rem;
  }
  h2 {
    font-size: 28px;
    font-size: 2.8rem;
  }
  h1.car-tips-h1 {
    font-size: 28px;
    font-size: 2.8rem;
  }
  h3 {
    font-size: 18px;
    font-size: 1.8rem;
  }
  h4 {
    font-size: 16px;
    font-size: 1.6rem;
  }
  .desktop-view {
    display: none !important;
  }
  .navbar-fixed-bottom .navbar-collapse {
    max-height: inherit;
  }
  .navbar-fixed-top .navbar-collapse {
    max-height: inherit;
  }
  .navbar-inverse .navbar-collapse .navbar-nav > li > a:hover {
    color: #000;
  }
  .navbar-inverse .navbar-collapse .navbar-nav > li > a:focus {
    color: #000;
  }
  .navbar-inverse .navbar-collapse .panel {
    display: block;
  }
  .navbar-inverse .navbar-form {
    background-color: #fff;
  }
  .navbar-inverse .navbar-form .navbar-nav > li > a:hover {
    color: #000;
  }
  .navbar-inverse .navbar-form .navbar-nav > li > a:focus {
    color: #000;
  }
  .navbar-inverse .navbar-form .panel {
    display: block;
  }
  .navbar-inverse .navbar-nav li {
    border-bottom: 1px solid #ccc;
  }
  .navbar-inverse .navbar-nav li:nth-child(6) {
    border-bottom: 10px;
  }
  .segments .segment .photo.hybrids img {
    margin: 8px 0 12px 26px;
  }
  .home-intro {
    padding: 20px 0;
  }
  .home-intro .home-intro-content {
    flex-direction: column;
  }
  .home-intro .home-intro-content .left,
  .home-intro .home-intro-content .right {
    width: 100%;
  }
  .home-intro .container {
    padding-left: 30px;
    padding-right: 30px;
  }
  .home-intro .vehicle {
    float: none;
    margin: 0 auto;
    padding: 0 10px 20px;
    width: 280px;
  }
  .models-content {
    margin: 20px 0;
  }
  .models-subnav {
    background-color: #fff;
  }
  .nav-tabs-models {
    border-bottom: transparent;
  }
  .nav-tabs-models li {
    border-bottom: 4px solid #fff;
    width: 100%;
  }
  .nav-tabs-models .active {
    background-color: #ddd;
  }
  .error-msg {
    top: -100px;
    left: 11px;
    padding: 5px 14px 14px;
  }
  .error-msg:before {
    right: 179px;
    top: 67px;
    width: 20px;
    height: 20px;
    border-style: none solid solid none;
    box-shadow: 2px 2px 3.5px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 2px 2px 3.5px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 2px 2px 3.5px rgba(0, 0, 0, 0.5);
  }
  .options-shown {
    bottom: 185px;
  }
  .options-shown-prototype {
    bottom: 185px;
  }
  .top-photo .placeholder {
    height: 164px;
  }
  .top-photo .search-box {
    background: rgb(0, 0, 0);
    margin: 0;
    padding-top: 20px;
    position: relative;
    top: 156px;
    width: 100%;
  }
  .top-photo .search-reason {
    padding: 0 15px;
  }
  .top-photo .search-zip {
    padding: 0 15px;
  }
  .top-photo.top-photo-90 {
    margin: 60px auto 0 auto;
  }
  .top-photo.home1 {
    background: url(/rental/img/home/2018-Camry-480x656.jpg) no-repeat center top;
  }
  .top-photo.home2 {
    background: url(/rental/img/home/2019-RAV4-480x656.jpg) no-repeat center top;
  }
  .top-photo.home3 {
    background: url(/rental/img/home/2024-GrandHighlander-375x320.jpg) no-repeat center top;
    height: 320px;
    background-size: contain;
  }
  .top-photo.home4 {
    background: url(/rental/img/home/home-4-480.jpg) no-repeat;
  }
  .top-photo.home5 {
    background: url(/rental/img/home/home-5-480.jpg) no-repeat;
  }
  .top-photo.home6 {
    background: url(/rental/img/home/home-6-480.jpg) no-repeat;
  }
  .top-photo.home7 {
    background: url(/rental/img/home/Be-There-Mobile-tcom-2.jpg) no-repeat;
    background-size: 100%;
    background-color: #000;
  }
  .home-tagline {
    padding: 80px 0 0 0;
    width: 280px;
    margin: 0 auto;
  }
  .home-tagline .home-title {
    font-size: 2.6rem;
    line-height: 2.8rem;
    width: 255px;
    margin: 0 auto;
    float: none;
  }
  .home-tagline .home-title-logo {
    display: none;
  }
  .vehicle-page .search-box {
    display: none;
  }
  .top-photo.yaris {
    background: url(/rental/img/vehicles/yaris-480.jpg) no-repeat no-repeat center top;
  }
  .top-photo.yaris-ia {
    background: url(/rental/img/vehicles/yaris-ia-480.jpg) no-repeat;
  }
  .top-photo.corolla {
    background: url(/rental/img/vehicles/corolla-480.jpg) no-repeat;
  }
  .top-photo.corolla-im {
    background: url(/rental/img/vehicles/corolla-im-480.jpg) no-repeat;
  }
  .top-photo.camry {
    background: url(/rental/img/vehicles/camry-480.jpg) no-repeat;
  }
  .top-photo.avalon {
    background: url(/rental/img/vehicles/avalon-480.jpg) no-repeat;
  }
  .top-photo.eighty-six {
    background: url(/rental/img/vehicles/86-480.jpg) no-repeat;
  }
  .top-photo.sienna {
    background: url(/rental/img/vehicles/sienna-480.jpg) no-repeat;
  }
  .top-photo.tacoma {
    background: url(/rental/img/vehicles/tacoma-480.jpg) no-repeat;
  }
  .top-photo.tundra {
    background: url(/rental/img/vehicles/tundra-480.jpg) no-repeat;
  }
  .top-photo.rav4 {
    background: url(/rental/img/vehicles/rav4-480.jpg) no-repeat;
  }
  .top-photo.c-hr {
    background: url(/rental/img/vehicles/chr-480.jpg) no-repeat;
  }
  .top-photo.highlander {
    background: url(/rental/img/vehicles/highlander-480.jpg) no-repeat;
  }
  .top-photo.fourrunner {
    background: url(/rental/img/vehicles/4runner-480.jpg) no-repeat;
  }
  .top-photo.sequoia {
    background: url(/rental/img/vehicles/sequoia-480.jpg) no-repeat;
  }
  .top-photo.landcruiser {
    background: url(/rental/img/vehicles/landcruiser-480.jpg) no-repeat;
  }
  .top-photo.venza {
    background: url(/rental/img/vehicles/venza-480.jpg) no-repeat;
  }
  .top-photo.priusprime {
    background: url(/rental/img/vehicles/priusprime-480.jpg) no-repeat;
  }
  .top-photo.corollahatchback {
    background: url(/rental/img/vehicles/corollahatchback-480.jpg) no-repeat;
  }
  .top-photo.prius {
    background: url(/rental/img/vehicles/prius-480.jpg) no-repeat;
  }
  .top-photo.priusc {
    background: url(/rental/img/vehicles/priusc-480.jpg) no-repeat;
  }
  .top-photo.priusv {
    background: url(/rental/img/vehicles/priusv-480.jpg) no-repeat;
  }
  .top-photo.camry-hybrid {
    background: url(/rental/img/vehicles/camry-hybrid-480.jpg) no-repeat;
  }
  .top-photo.avalon-hybrid {
    background: url(/rental/img/vehicles/avalon-hybrid-480.jpg) no-repeat;
  }
  .top-photo.rav4-hybrid {
    background: url(/rental/img/vehicles/rav4-hybrid-480.jpg) no-repeat;
  }
  .top-photo.highlander-hybrid {
    background: url(/rental/img/vehicles/highlander-hybrid-480.jpg) no-repeat;
  }
  .top-photo.corolla-hybrid {
    background: url(/rental/img/vehicles/corolla-hybrid-480.jpg) no-repeat;
  }
  .vehicle-top-photo .placeholder {
    height: 300px;
  }
  .vehicle-top-photo .search-box {
    background: rgba(0, 0, 0, 0.8);
    margin: 0;
    padding-top: 20px;
    position: relative;
    top: 300px;
    width: 100%;
  }
  .vehicle-top-photo .search-title {
    display: none;
  }
  .icon-button-lg a {
    font-size: 14px;
    font-size: 1.4rem;
    padding: 8px 15px;
  }
  .icon-button-lg i {
    font-size: 22px;
    line-height: 22px;
    margin-right: 6px;
    vertical-align: sub;
  }
  .vehicle-header {
    margin: 10px 0 20px;
  }
  .vehicle-name {
    margin-right: 0;
  }
  .vehicle-stats {
    margin-top: 10px;
    width: 100%;
  }
  .vehicle-stat {
    width: 50%;
  }
  .vehicle-stat .icon {
    float: left;
    margin-top: 10px;
  }
  .vehicle-buttons {
    width: 100%;
  }
  .vehicle-buttons .icon-button {
    width: 100%;
  }
  .vehicle-buttons .icon-button:nth-child(2n) {
    float: right;
    margin-top: 6px;
  }
  .vehicle-buttons .icon-button:nth-child(3n) {
    display: none;
  }
  .vehicle-buttons .icon-button a {
    width: 100%;
    margin-left: 0;
  }
  .vehicle-buttons .icon-button button {
    width: 100%;
    margin-left: 0;
  }
  .vehicle-photo {
    display: none;
  }
  .section .section-inner-container .inner-panel {
    text-align: center;
  }
  .section .section-inner-container .inner-panel:nth-child(2) {
    text-align: center;
  }
  .kbb-card .ratings {
    padding: 10px 0;
  }
  .top-photo.about {
    background: url(/rental/img/about-480-2019.jpg) no-repeat;
  }
  .top-photo.about .placeholder {
    height: 195px;
  }
  .about-content {
    margin: 20px 0;
  }
  .about-content .logo-graphic {
    display: none;
  }
  figcaption {
    background: #e10a1d;
    opacity: 1;
  }
  figcaption .caption {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.9rem;
  }
  figcaption .title {
    font-size: 20px;
    font-size: 2rem;
    margin-top: 8px;
    margin-bottom: 10px;
  }
  .cap-bot:before {
    font-size: 14px;
    font-size: 1.4rem;
    height: 40px;
  }
  .cap-bot figcaption {
    position: relative;
  }
  .top-photo.faq {
    background: url(/rental/img/faq-480.jpg) no-repeat;
  }
  .top-photo.faq .placeholder {
    height: 195px;
  }
  .faq-content {
    margin: 20px 0;
  }
  .top-photo.cartips {
    background: url(/rental/img/cartips-480.jpg) no-repeat;
  }
  .top-photo.cartips .placeholder {
    height: 195px;
  }
  .dealer-search .dealer-search-content .reason-tiles .reason-tile-container .reason-tile {
    background: #e10a1d;
    display: table;
    height: 80px;
    padding: 0px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .dealer-search .dealer-search-content .reason-tiles .reason-tile-container .reason-tile img {
    display: none;
  }
  .dealer-search .dealer-search-content .reason-tiles .reason-tile-container .reason-tile .reason {
    display: table-cell;
    font-size: 12px;
    font-size: 1.2rem;
    text-align: center;
    vertical-align: middle;
  }
  .dealer-search .dealer-search-content .dealer-form .form-button {
    margin: 30px 0 20px;
    padding: 0 10px;
  }
  .dealer-search .dealer-search-content .dealer-form .form-button a {
    width: 100%;
  }
  .dealer-results .content-page-title {
    border-top: 1px solid #ccc;
    margin-top: 50px;
  }
  .dealer-results .dealer-result .result-container .result-header .result-count .number {
    font-size: 12px;
    font-size: 1.2rem;
    left: 0px;
  }
  .dealer-results .dealer-result .result-container .result-header .dealer {
    margin: 2px 0 8px 0;
  }
  .dealer-results .dealer-result .result-container .result-header .award {
    float: none;
    margin: 10px auto;
    width: 106px;
  }
  .dealer-results .dealer-result .result-container .result-header .award img {
    width: 100%;
  }
  .dealer-results .dealer-result .result-container .result-body .dealer-contact-info .phone {
    font-family: "ToyotaFontSemiBold", Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .dealer-results .dealer-result .result-container .result-body .dealer-contact-info .phone i {
    color: #e10a1d;
    margin-right: 6px;
  }
  .dealer-results .dealer-result .result-container .result-footer {
    padding: 0px;
  }
  .dealer-results .dealer-result .result-container .result-footer .dealer-link-button {
    border: 1px solid #eee;
    text-align: center;
  }
  .dealer-results .dealer-result .result-container .result-footer .dealer-link-button a {
    font-size: 1.4rem;
  }
  .dealer-results .dealer-result .result-container .result-footer .contact-red {
    background-color: #e10a1d;
  }
  .dealer-results .dealer-result .result-container .result-footer .contact-red a {
    color: #fff;
  }
  .dealer-results .dealer-result .result-container .result-footer .dealer-link-fw {
    float: left;
    width: 100%;
    margin: 0;
    padding: 10px 15px;
    background-color: #e10a1d;
  }
  .dealer-results .dealer-result .result-container .result-footer .dealer-link-fw a {
    color: #fff;
  }
  .dealer-results .dealer-result .result-container .result-footer .icon-left {
    float: left;
    width: 50%;
    margin: 0;
    padding: 10px 15px;
  }
  .dealer-results .dealer-result .result-container .result-footer .icon-left i {
    display: none;
  }
  .dealer-results .dealer-result .result-container .result-footer .icon-right {
    margin: 0;
    padding: 10px 15px;
    width: 50%;
  }
  .dealer-results .dealer-result .result-container .result-footer .icon-right i {
    display: none;
  }
  .error-page-padding {
    padding: 70px 0 70px;
  }
  .modal-response {
    padding: 0px 20px 30px;
  }
  .zip-modal input {
    padding: 10px 10px 7.5px 15px;
  }
  .cd-top {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  h1 {
    font-size: 45px;
    font-size: 4.5rem;
  }
  h2.car-tips-h2 {
    font-size: 45px;
    font-size: 4.5rem;
  }
  .navbar-inverse .navbar-brand {
    top: 20px;
    width: 175px;
  }
  .navbar-brand img {
    width: 175px;
  }
  .home-intro {
    padding: 30px 0;
  }
  .home-intro .vehicle {
    width: 400px;
  }
  .copyright-row-lg {
    margin-top: 15px;
  }
  .top-photo .search-box {
    top: 70px;
  }
  .top-photo .search-title {
    font-size: 22px;
    font-size: 2.2rem;
    line-height: 26px;
    margin: 0 10px 10px 10px;
  }
  .top-photo .search-reason {
    padding: 0 15px;
    width: 100%;
  }
  .top-photo .search-zip {
    padding: 0 15px;
  }
  .home-tagline {
    padding-top: 100px;
  }
  .home-tagline .home-title {
    font-size: 2.8rem;
    line-height: 2.9rem;
    width: 275px;
  }
  .home-tagline .home-title-logo {
    margin-left: 15px;
    width: 130px;
    margin-top: 8px;
  }
  .vehicle-name {
    float: inherit;
  }
  .vehicle-stats {
    margin-top: 10px;
    width: 40%;
  }
  .vehicle-stat {
    width: 50%;
  }
  .vehicle-stat .icon {
    float: left;
    margin-top: 10px;
  }
  .vehicle-buttons {
    float: right;
    margin-left: 40px;
  }
  .vehicle-photo {
    width: 45%;
  }
  .edmunds-card .ratings .disclaimer {
    margin: 8px 0 51px;
  }
  .dealer-search .dealer-search-content .reason-tiles .reason-tile-container .reason-tile .reason {
    font-size: 12px;
    font-size: 1.2rem;
  }
  .dealer-results .dealer-result .result-container .result-header .award {
    float: left;
  }
  .error-page-padding {
    padding: 90px 0 250px;
  }
}
@media (min-width: 481px) and (max-width: 991px) {
  h1 {
    font-size: 42px;
    font-size: 4.2rem;
  }
  h2.car-tips-h2 {
    font-size: 42px;
    font-size: 4.2rem;
  }
  h2 {
    font-size: 30px;
    font-size: 3rem;
  }
  h1.car-tips-h1 {
    font-size: 30px;
    font-size: 3rem;
  }
  h3 {
    font-size: 20px;
    font-size: 2rem;
  }
  h4 {
    font-size: 18px;
    font-size: 1.8rem;
  }
  h5 {
    font-size: 16px;
    font-size: 1.6rem;
  }
  .mobile-view-only {
    display: none !important;
  }
  .desktop-view {
    display: none !important;
  }
  .navbar-fixed-bottom .navbar-collapse {
    max-height: inherit;
  }
  .navbar-fixed-top .navbar-collapse {
    max-height: inherit;
  }
  .navbar-inverse .navbar-collapse .navbar-nav > li > a:hover {
    color: #000;
  }
  .navbar-inverse .navbar-collapse .navbar-nav > li > a:focus {
    color: #000;
  }
  .navbar-inverse .navbar-collapse .panel {
    display: block;
  }
  .navbar-inverse .navbar-form {
    background-color: #fff;
  }
  .navbar-inverse .navbar-form .navbar-nav > li > a:hover {
    color: #000;
  }
  .navbar-inverse .navbar-form .navbar-nav > li > a:focus {
    color: #000;
  }
  .navbar-inverse .navbar-form .panel {
    display: block;
  }
  .navbar-inverse .navbar-nav li {
    border-bottom: 1px solid #ccc;
  }
  .home-intro {
    padding: 20px 0;
  }
  .home-intro .vehicle {
    float: none;
    margin: 0 auto;
    padding: 0 10px 20px;
    width: 400px;
  }
  .models-subnav {
    background-color: #fff;
  }
  .nav-tabs-models {
    background-color: transparent;
  }
  .nav-tabs-models li {
    width: 50%;
  }
  .nav-tabs-models li:nth-child(1) {
    border: 2px solid #fff;
    border-left: none;
  }
  .nav-tabs-models li:nth-child(2) {
    border: 2px solid #fff;
    border-right: none;
  }
  .nav-tabs-models li:nth-child(3) {
    border: 2px solid #fff;
    border-left: none;
  }
  .nav-tabs-models li:nth-child(4) {
    border: 2px solid #fff;
    border-right: none;
  }
  .nav-tabs-models li:nth-child(5) {
    border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;
    width: 100%;
  }
  .nav-tabs-models .active {
    background-color: #ddd;
  }
  .error-msg {
    top: -100px;
    left: 11px;
    padding: 5px 14px 14px;
  }
  .error-msg:before {
    right: 179px;
    top: 67px;
    width: 20px;
    height: 20px;
    border-style: none solid solid none;
    box-shadow: 2px 2px 3.5px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 2px 2px 3.5px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 2px 2px 3.5px rgba(0, 0, 0, 0.5);
  }
  .top-photo .placeholder {
    height: 600px;
  }
  .top-photo .search-box {
    margin: 0;
    padding-top: 20px;
    bottom: 0;
  }
  .top-photo .search-reason {
    padding: 0 15px;
  }
  .top-photo .search-zip {
    padding: 0 15px;
  }
  .top-photo .search-alt {
    margin: 5px 0;
  }
  .top-photo.home1 {
    background: url(/rental/img/home/2018-Camry-768x600.jpg) no-repeat;
  }
  .top-photo.home2 {
    background: url(/rental/img/home/2019-RAV4-768x600.jpg) no-repeat;
  }
  .top-photo.home3 {
    background: url(/rental/img/home/2024-GrandHighlander-768x600.jpg) no-repeat;
    background-size: cover;
  }
  .top-photo.home4 {
    background: url(/rental/img/home/home-4-768.jpg) no-repeat;
  }
  .top-photo.home5 {
    background: url(/rental/img/home/home-5-768.jpg) no-repeat;
  }
  .top-photo.home6 {
    background: url(/rental/img/home/home-6-768.jpg) no-repeat;
  }
  .top-photo.home7 {
    background: url(/rental/img/home/be-there-trac-768x600-2.jpg) no-repeat;
  }
  .home-tagline {
    padding: 80px 0 0 0;
    width: 420px;
    margin: 0 auto;
  }
  .home-tagline .home-title {
    font-size: 2.8rem;
    line-height: 2.9rem;
    width: 275px;
  }
  .home-tagline .home-title-logo {
    margin-left: 15px;
    width: 130px;
    margin-top: 8px;
  }
  .vehicle-page .search-box {
    display: none;
  }
  .top-photo.yaris {
    background: url(/rental/img/vehicles/yaris-768.jpg) no-repeat;
  }
  .top-photo.yaris-ia {
    background: url(/rental/img/vehicles/yaris-ia-768.jpg) no-repeat;
  }
  .top-photo.corolla {
    background: url(/rental/img/vehicles/corolla-768.jpg) no-repeat;
  }
  .top-photo.corolla-im {
    background: url(/rental/img/vehicles/corolla-im-768.jpg) no-repeat;
  }
  .top-photo.camry {
    background: url(/rental/img/vehicles/camry-768.jpg) no-repeat;
  }
  .top-photo.avalon {
    background: url(/rental/img/vehicles/avalon-768.jpg) no-repeat;
  }
  .top-photo.eighty-six {
    background: url(/rental/img/vehicles/86-768.jpg) no-repeat;
  }
  .top-photo.sienna {
    background: url(/rental/img/vehicles/sienna-768.jpg) no-repeat;
  }
  .top-photo.tacoma {
    background: url(/rental/img/vehicles/tacoma-768.jpg) no-repeat;
  }
  .top-photo.tundra {
    background: url(/rental/img/vehicles/tundra-768.jpg) no-repeat;
  }
  .top-photo.rav4 {
    background: url(/rental/img/vehicles/rav4-768.jpg) no-repeat;
  }
  .top-photo.c-hr {
    background: url(/rental/img/vehicles/chr-768.jpg) no-repeat;
  }
  .top-photo.highlander {
    background: url(/rental/img/vehicles/highlander-768.jpg) no-repeat;
  }
  .top-photo.fourrunner {
    background: url(/rental/img/vehicles/4runner-768.jpg) no-repeat;
  }
  .top-photo.sequoia {
    background: url(/rental/img/vehicles/sequoia-768.jpg) no-repeat;
  }
  .top-photo.landcruiser {
    background: url(/rental/img/vehicles/landcruiser-768.jpg) no-repeat;
  }
  .top-photo.venza {
    background: url(/rental/img/vehicles/venza-768.jpg) no-repeat;
  }
  .top-photo.priusprime {
    background: url(/rental/img/vehicles/priusprime-768.jpg) no-repeat;
  }
  .top-photo.corollahatchback {
    background: url(/rental/img/vehicles/corollahatchback-768.jpg) no-repeat;
  }
  .top-photo.prius {
    background: url(/rental/img/vehicles/prius-768.jpg) no-repeat;
  }
  .top-photo.priusc {
    background: url(/rental/img/vehicles/priusc-768.jpg) no-repeat;
  }
  .top-photo.priusv {
    background: url(/rental/img/vehicles/priusv-768.jpg) no-repeat;
  }
  .top-photo.camry-hybrid {
    background: url(/rental/img/vehicles/camry-hybrid-768.jpg) no-repeat;
  }
  .top-photo.avalon-hybrid {
    background: url(/rental/img/vehicles/avalon-hybrid-768.jpg) no-repeat;
  }
  .top-photo.rav4-hybrid {
    background: url(/rental/img/vehicles/rav4-hybrid-768.jpg) no-repeat;
  }
  .top-photo.highlander-hybrid {
    background: url(/rental/img/vehicles/highlander-hybrid-768.jpg) no-repeat;
  }
  .top-photo.corolla-hybrid {
    background: url(/rental/img/vehicles/corolla-hybrid-768.jpg) no-repeat;
  }
  .vehicle-top-photo .placeholder {
    height: 600px;
  }
  .vehicle-top-photo .search-box {
    width: 80%;
    height: 225px;
    top: 80px;
    left: 10%;
    margin: 0;
    padding-top: 0px;
  }
  .vehicle-top-photo .search-title {
    display: block;
  }
  .vehicle-header {
    margin: 15px 0 30px;
  }
  .vehicle-name {
    float: inherit;
  }
  .vehicle-stats {
    margin-top: 10px;
    width: 55%;
  }
  .vehicle-stat {
    padding: 8px 14px;
    width: 50%;
  }
  .vehicle-buttons {
    float: left;
    margin-top: 10px;
    padding: 0 10px;
    width: 45%;
  }
  .vehicle-buttons .icon-button {
    width: 100%;
  }
  .vehicle-buttons .icon-button:nth-child(2n) {
    float: right;
    margin-top: 6px;
  }
  .vehicle-buttons .icon-button:nth-child(3n) {
    display: none;
  }
  .vehicle-buttons .icon-button a {
    padding: 6px;
    width: 100%;
  }
  .vehicle-buttons .icon-button a i {
    font-size: 18px;
    line-height: 14px;
    margin-right: 5px;
    vertical-align: inherit;
  }
  .vehicle-buttons .icon-button button {
    padding: 6px;
    width: 100%;
  }
  .vehicle-buttons .icon-button button i {
    font-size: 18px;
    line-height: 14px;
    margin-right: 5px;
    vertical-align: inherit;
  }
  .vehicle-photo {
    display: none;
  }
  .section .section-inner-container .inner-panel {
    text-align: center;
  }
  .section .section-inner-container .inner-panel:nth-child(2) {
    text-align: center;
  }
  .top-photo.about {
    background: url(/rental/img/about-768-2019.jpg) no-repeat;
    background-size: cover;
  }
  .top-photo.about .placeholder {
    height: 312px;
  }
  figure.about1 .reason-image {
    background: url(/rental/img/home/reason-1-768.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 0;
    padding-top: 56.5%;
  }
  figure.about2 .reason-image {
    background: url(/rental/img/home/reason-2-768.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 0;
    padding-top: 56.5%;
  }
  figure.about3 .reason-image {
    background: url(/rental/img/home/reason-3-768.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 0;
    padding-top: 56.5%;
  }
  figure.about4 .reason-image {
    background: url(/rental/img/home/reason-4-768.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 0;
    padding-top: 56.5%;
  }
  .top-photo.faq {
    background: url(/rental/img/faq-768.jpg) no-repeat;
    background-size: cover;
  }
  .top-photo.faq .placeholder {
    height: 312px;
  }
  .top-photo.cartips {
    background: url(/rental/img/cartips-768.jpg) no-repeat;
    background-size: cover;
  }
  .top-photo.cartips .placeholder {
    height: 312px;
  }
  .dealer-search .dealer-search-content .dealer-form .tab-content {
    padding: 30px 80px 0;
  }
  .dealer-results .content-page-title {
    border-top: 1px solid #ccc;
    margin-top: 50px;
  }
  .dealer-results .dealer-result .result-container .result-header .award {
    float: right;
    margin-right: 10px;
  }
  .dealer-results .dealer-result .result-container .result-body .dealer-contact-info .phone {
    font-family: "ToyotaFontSemiBold", Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .dealer-results .dealer-result .result-container .result-body .dealer-contact-info .phone i {
    color: #e10a1d;
    margin-right: 8px;
  }
  .dealer-results .dealer-result .result-container .result-footer .icon-left {
    float: none;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .dealer-results .dealer-result .result-container .result-footer .icon-right {
    float: none;
    margin-bottom: 10px;
  }
  .error-page-padding {
    padding: 80px 0 200px;
  }
  .modal-response {
    padding: 10px 40px 50px;
  }
  .cd-top {
    bottom: 30px;
    right: 20px;
  }
}
@media (min-width: 992px) {
  .mobile-view {
    display: none !important;
  }
  .mobile-view-only {
    display: none !important;
  }
  .navbar-inverse {
    min-height: 121px;
  }
  .navbar-inverse .stripe {
    display: flex;
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #d8d8d8;
  }
  .navbar-inverse .stripe .container {
    padding: 0;
  }
  .navbar-inverse .stripe ul {
    display: flex;
    justify-content: flex-end;
    list-style: none;
  }
  .navbar-inverse .stripe ul li {
    font-family: "ToyotaFontSemiBold", Arial, Helvetica, sans-serif;
    display: flex;
    font-size: 11px;
    padding: 10px 0 10px 10px;
    margin: 0;
  }
  .navbar-inverse .stripe ul li:after {
    content: "|";
    padding-left: 10px;
  }
  .navbar-inverse .stripe ul li:last-child:after {
    display: none;
  }
  .navbar-inverse .stripe ul li a {
    color: #767676;
  }
  .navbar-inverse .stripe ul li a:hover {
    color: #e10a1d;
    outline: none !important;
  }
  .navbar-inverse .stripe ul li a:focus {
    color: #e10a1d;
  }
  .navbar-inverse .stripe ul li input {
    line-height: 1;
    border: none;
  }
  .navbar-inverse .navbar-nav {
    border: none;
    font-family: "ToyotaFontSemiBold", Arial, Helvetica, sans-serif;
    float: right;
  }
  .navbar-inverse .navbar-nav li.rt-btn {
    display: flex;
    position: relative;
    min-width: 125px;
    margin-left: 15px;
    background: #e10a1d;
    line-height: 1;
    margin-top: 0;
  }
  .navbar-inverse .navbar-nav li.rt-btn a {
    padding: 19px 20px 18px;
    color: #fff !important;
  }
  .navbar-inverse .navbar-nav .line-item {
    background-color: transparent;
  }
  .navbar-inverse .navbar-nav li a {
    padding: 19px 20px 18px 20px;
    color: #000;
    font-size: 14px;
  }
  .navbar-inverse .navbar-nav li a:focus {
    color: #e10a1d !important;
  }
  .navbar-inverse .navbar-nav li a:hover {
    color: #890713 !important;
    outline: none !important;
  }
  .navbar-inverse .navbar-nav li a:visited {
    color: #e10a1d;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu li a {
    padding: 10px 20px;
  }
  .navbar-inverse .nav-tabs {
    margin: 25px auto 0 auto;
    width: 750px;
  }
  .navbar-inverse .lang {
    float: right;
    margin-right: 10px;
  }
  .navbar-inverse .lang li a {
    padding-right: 0px;
  }
  .navbar-inverse .lang li a:hover {
    background-color: inherit !important;
    color: #e10a1d !important;
  }
  .home-hero-content {
    margin: -40px auto 10px;
    position: relative;
    z-index: 2;
    width: 89%;
    max-width: 1180px;
  }
  .segments {
    padding: 0;
  }
  .segments .slider-panel-content {
    flex-direction: row;
    padding: 20px 0px 40px 0;
  }
  .fad-btn {
    margin-bottom: 150px;
  }
  .home-intro {
    margin-top: 0;
  }
  .home-intro .home-intro-content {
    flex-direction: row;
  }
  .home-intro .video {
    margin: 4px 0 10px 40px;
  }
  footer a:hover {
    text-decoration: underline !important;
  }
  footer .ccpa-links {
    text-align: right;
    padding-right: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: transparent;
  }
  footer .ccpa-links .no-sell {
    display: inline-block;
    text-transform: uppercase;
    color: #e10a1d;
    font-size: 1.6rem;
  }
  footer .ccpa-links .consent {
    display: inline-block;
    color: #000;
    font-size: 1.3rem;
  }
  footer .footer-col:nth-child(4) {
    margin-top: 15px;
  }
  footer .panel-group .panel .panel-body li a {
    padding: 15px 20px 15px 40px;
  }
  .top-photo {
    max-width: 1280px;
    margin-top: 121px;
  }
  .vehicle-photo {
    width: inherit;
  }
  .dealers-panel {
    width: 350px;
    height: 650px;
    padding-top: 25px;
    overflow: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
  }
  .dealer-search {
    margin-top: 130px;
  }
  .dealer-search .content-page-title {
    position: inherit;
    margin-left: -15px;
  }
  .dealer-results .dealer-result .result-container .result-body .dealer-contact-info .address {
    cursor: default;
    pointer-events: none;
  }
  .dealer-results .dealer-result .result-container .result-body .dealer-contact-info .address a {
    color: #000;
  }
  .dealer-results .dealer-result .result-container .result-body .dealer-contact-info .address .icon {
    display: none;
  }
  .modal-dialog {
    width: 95%;
  }
  .field-con {
    margin: 0;
    width: 202px;
  }
  .field-con.cad {
    width: 100%;
  }
  .form-group-inline {
    width: auto;
  }
  .form-group-inline.cad {
    width: 100%;
    margin-bottom: 15px;
  }
  .tcuv-form-field-input {
    width: 200px;
  }
  .tcuv-form-field-input.cad {
    padding: 14px 10px 13px 17px;
    width: 100%;
  }
}
@media (min-width: 992px) and (min-width: 768px) {
  .tcuv-form-field-input.cad {
    width: 399px;
  }
}
@media (min-width: 768px) {
  .navbar-inverse #menuTabs .tab-content .tab-pane {
    width: 750px;
    max-width: 750px;
  }
  .models-list {
    max-width: 980px;
    flex-flow: wrap;
    justify-content: space-evenly;
    padding: 0 0;
  }
  .menu-vehicle {
    padding: 0 20px 0 0;
    width: 239px;
  }
  .rt-select .select-arrow.for-city img {
    display: inline-block;
    top: -3px;
    left: 6px;
  }
  .rt-select .state1-select {
    left: 30px;
  }
  .submitMed {
    display: block;
    padding: 19px 40px 18px;
    width: 185px;
    margin-bottom: 25px;
  }
  .col-divider-pipe.lg {
    display: none;
  }
  .col-divider-pipe.med {
    position: absolute;
    top: 20px;
    display: inline-block;
    height: 43px;
    width: 2px;
    right: 5px;
    background-color: #d8d8d8;
  }
}
@media (min-width: 992px) {
  .home-search-box {
    max-width: 980px;
    height: 202px;
    padding: 30px 80px 40px 80px;
  }
  .home-search-box .home-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 815px;
    border: 1px solid #d8d8d8;
    height: 60px;
    margin: 15px 0;
  }
  .home-search-box .home-form .home-form-select {
    display: flex;
    align-items: center;
    width: 312px;
    height: 100%;
    border: none;
    border-right: 1px solid #d8d8d8;
  }
  .home-search-box .home-form .search-by-zip {
    width: 312px;
    position: relative;
    display: none;
  }
  .home-search-box .home-form .search-by-zip .search-zip-in {
    border: none;
    margin-top: 0;
  }
  .home-search-box .home-form .search-by-city {
    width: 312px;
    position: relative;
    display: none;
  }
  .home-search-box .home-form .search-by-city .search-city-in {
    width: 50%;
    padding-left: 15px;
    border: none;
    border-right: 1px solid #d8d8d8;
    margin-top: 0;
  }
  .home-search-box .home-form .search-by-city .search-state-select {
    width: 50%;
    padding-left: 15px;
    min-height: 60px;
    border: none;
    margin-top: 0;
  }
  .home-search-box .home-form .search-btn {
    display: flex;
    align-self: flex-end;
    margin-top: 0;
  }
  .home-search-box .home-form .search-btn a {
    width: 200px;
  }
  .navbar-inverse #menuTabs .tab-content .tab-pane {
    margin: 0 auto;
    width: 970px;
    max-width: 970px;
  }
  .menu-vehicle {
    padding: 0 20px 0 0;
    width: 239px;
  }
  .rt-select .select-arrow.for-city img {
    display: inline-block;
    top: -3px;
    left: -26px;
  }
  .rt-select .state1-select {
    left: 0;
  }
  .btn-primary.submitDesktop {
    display: block;
    padding: 19px 40px 18px;
    margin-right: 75px;
  }
  .submitMed {
    display: none;
  }
  .col-divider-pipe.lg {
    display: inline-block;
  }
  .col-divider-pipe.med {
    display: none;
  }
}
@media (min-width: 992px) {
  .navbar-inverse .nav-tabs {
    margin: 25px auto 0 auto;
    width: 970px;
  }
  .navbar-inverse .navbar-brand {
    width: 234px;
    padding: 0;
    top: 15px;
  }
  .navbar > .container .navbar-brand {
    margin-left: -25px;
  }
  .navbar > .container-fluid .navbar-brand {
    margin-left: -25px;
  }
  .navbar-brand img {
    width: 234px;
  }
  .home-intro h1 {
    font-size: 32px;
  }
  .top-photo .search-box {
    top: 100px;
  }
}
@media (min-width: 1281px) {
  .home-hero-content {
    margin: -83px auto 20px;
  }
  .home-hero-content .reason-tiles {
    padding-top: 40px;
  }
  .error-page-padding {
    padding: 120px 0 400px;
  }
}
@media (min-width: 1200px) {
  .menu-vehicle {
    padding: 0 40px 0 0;
    width: 289px;
  }
}
@media (min-width: 1024px) {
  .top-photo .search-box h2 {
    margin-top: 10px;
    font-size: 30px;
    font-weight: normal;
  }
}
@media (min-width: 768px) and (max-width: 884px) {
  .top-photo .search-title {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 22px;
  }
}
@media screen and (min-width: 768px) {
  .cartips-content .cartips-nav .collapse {
    display: inline-block;
  }
}
@media (min-width: 760px) {
  .dealers-con {
    flex-direction: row;
  }
}
@media (min-width: 320px) and (max-width: 350px) {
  .dealer-results .dealer-result .result-container .result-header .dealer {
    max-width: 200px;
  }
  .dealer-results .dealer-result .result-container .result-body .dealer-contact-info .address a div.pull-left {
    max-width: 200px;
  }
}
.modal-header .disclosure-close {
  display: block;
  position: absolute;
  top: 25px;
  right: 25px;
}

.modal-title-wrap {
  max-width: 70%;
}

@media (min-width: 768px) {
  .modal-title-wrap {
    padding: 45px 45px 25px 45px;
  }
}
#discolsuresModal .modal-dialog {
  overflow-y: initial !important;
}

#discolsuresModal .modal-body {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

.modal-body-wrapper {
  height: 100%;
  -webkit-transform: scale(1);
  transform: scale(1);
}

#disclosuresContent .disclosures-modal-content {
  position: relative;
  padding: 0 !important;
  width: 100%;
  height: 90%;
}

.disclosures-title {
  font-family: "ToyotaFontSemiBold", Arial, Helvetica, sans-serif;
  font-size: 24px;
}

.disclosures-list {
  list-style: none;
  padding: 30px 0;
}
.disclosures-list li {
  position: relative;
  font-size: 11px;
  line-height: 1.2;
  padding-left: 45px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-right: 45px;
}

.disclosures-list li.is-active {
  background-color: #dee2e8;
}

.disclosures-list li.is-active:before {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 0;
  height: 0;
  border-top: 5.5px solid transparent;
  border-bottom: 5.5px solid transparent;
  border-left: 7.5px solid #c00;
  content: "";
}

@media print {
  .no-print {
    display: none;
  }
}
@media screen and (min-width: 48em) {
  #ensModalWrapper {
    width: 711px;
    margin-top: 50px;
    margin-bottom: 50px;
    height: 85%;
  }
}
.app-continer {
  margin: 50px auto 50px auto;
  max-width: 980px;
}
@media (min-width: 768px) {
  .app-continer {
    margin: 75px auto 75px auto;
  }
}

.toyota-app-head {
  display: flex;
  background-color: #f6f6f6;
  max-width: 1500px;
  padding: 0;
  margin: 0 auto;
  min-height: 200px;
  margin-top: 71px;
}
.toyota-app-head .head-con {
  padding: 0 25px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
.toyota-app-head .head-con .head-left {
  display: flex;
  flex-direction: column;
  padding: 0;
}
.toyota-app-head .head-con .head-left .head-left-top {
  display: flex;
  flex-direction: column;
  margin-bottom: 35px;
}
.toyota-app-head .head-con .head-left .head-left-top .app-title {
  font-family: "ToyotaFontSemiBold", Arial, Helvetica, sans-serif;
  font-size: 24px;
  text-align: center;
}
.toyota-app-head .head-con .head-left .head-left-top .app-logo {
  width: 50px;
  height: 50px;
  margin: 50px auto 0 auto;
}
.toyota-app-head .head-con .head-left .app-note {
  font-family: "ToyotaFontSemiBold", Arial, Helvetica, sans-serif;
  font-size: 24px;
  text-align: center;
  margin-bottom: 5px;
}
.toyota-app-head .head-con .head-left h1 {
  font-size: 36px;
  line-height: 1.1;
}
.toyota-app-head .head-con .head-left .app-sub-text {
  margin-top: 25px;
  width: 100%;
  font-size: 14px;
}
.toyota-app-head .head-con .head-right {
  position: relative;
}
.toyota-app-head .head-con .head-right .featured-app {
  display: flex;
  position: relative;
  width: 80%;
  margin: 25px auto 0 auto;
}
@media (min-width: 768px) {
  .toyota-app-head .head-con {
    flex-direction: row;
    max-width: 1200px;
    min-height: 530px;
    justify-content: space-between;
  }
  .toyota-app-head .head-con .head-left {
    width: 50%;
    padding: 90px 0 0 90px;
  }
  .toyota-app-head .head-con .head-left .head-left-top {
    margin-bottom: 15px;
    flex-direction: row;
  }
  .toyota-app-head .head-con .head-left .head-left-top .app-logo {
    margin: 0 15px 0 0;
  }
  .toyota-app-head .head-con .head-left .head-left-top .app-title {
    text-align: left;
  }
  .toyota-app-head .head-con .head-left .app-note {
    text-align: left;
    margin-bottom: 5px;
  }
  .toyota-app-head .head-con .head-left .app-sub-text {
    margin-top: 25px;
    width: 84%;
    font-size: 14px;
  }
  .toyota-app-head .head-con .head-right {
    width: 50%;
  }
  .toyota-app-head .head-con .head-right .app-mobile {
    padding: 90px 90px 0 0;
    display: none;
  }
  .toyota-app-head .head-con .head-right .featured-app {
    width: 231px;
    position: absolute;
    display: block;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
  }
}

.toyota-app-body {
  padding: 0 25px;
}
.toyota-app-body .app-disclaimer {
  margin: 0 auto;
  max-width: 820px;
  font-size: 10px;
  line-height: 13px;
  margin-top: 15px;
  text-align: center;
  color: #767676;
}
.toyota-app-body .app-features-list {
  display: flex;
  flex-direction: column;
  list-style: none;
}
.toyota-app-body .app-features-list li {
  width: 100%;
  margin-bottom: 25px;
}
.toyota-app-body .app-features-list li .icon {
  display: block;
  margin: 0 auto 10px auto;
  height: 50px;
}
.toyota-app-body .app-features-list li p {
  font-family: "ToyotaFontRegular", Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 21px;
  color: #767676;
  text-align: center;
}
@media (min-width: 768px) {
  .toyota-app-body {
    padding: 0;
  }
  .toyota-app-body .app-features-list {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .toyota-app-body .app-features-list li {
    width: 33%;
  }
}

.get-started {
  display: flex;
  flex-direction: column;
  max-width: 820px;
  margin: 0 auto;
  padding: 0 25px;
}
.get-started .get-left img {
  display: block;
  margin: 0 auto;
  max-width: 255px;
}
.get-started .get-right {
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-top: 50px;
}
.get-started .get-right .get-right-block {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: auto auto;
  width: auto;
}
.get-started .get-right .get-right-block h2 {
  display: block;
  width: 60%;
  font-family: "ToyotaFontSemiBold", Arial, Helvetica, sans-serif;
  font-size: 20px;
  line-height: 28px;
}
.get-started .get-right .get-right-block ol {
  padding-left: 15px;
}
.get-started .get-right .get-right-block ol li {
  font-family: "ToyotaFontRegular", Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #767676;
}
@media (min-width: 768px) {
  .get-started {
    flex-direction: row;
    padding: 0;
  }
  .get-started .get-left {
    width: 50%;
  }
  .get-started .get-left img {
    display: block;
    margin: 0;
  }
  .get-started .get-right {
    width: 50%;
    margin-top: 0;
  }
  .get-started .get-right .get-right-block {
    padding: 0 50px;
  }
}

.bottom-section {
  margin: 50px 0;
}
.bottom-section .app-badges {
  margin: 25px;
  display: flex;
  justify-content: center;
}
.bottom-section .app-badges a {
  margin: 0 10px;
}
@media (min-width: 768px) {
  .bottom-section {
    margin: 100px 0;
  }
}

@media (max-width: 991px) {
  .navbar-header {
    float: none;
  }
  .navbar-left,
  .navbar-right {
    float: none !important;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }
  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
    display: none !important;
  }
  .navbar-nav {
    float: none !important;
    margin-top: 7.5px;
  }
  .navbar-nav > li {
    float: none;
  }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .collapse.in {
    display: block !important;
  }
}